import {ReactNode, useEffect, useState} from "react";
import {getProject, getProjectApps, getResume, updateAppStatus} from "../../ApiClient";
import Loading from "../../components/statics/Loading"
import {Typography, Button, Grid, Tab, Tabs, Divider} from "@mui/material";
import {useParams} from "react-router-dom";
import {isFailRes, isApplicationArr, isProject} from "../../guards";
import InfoDisplay from "../../components/abstract/InfoDisplay";
import ConfirmDecision from "../../components/abstract/ConfirmDecision";
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check';
import DownloadIcon from '@mui/icons-material/Download';
import {useErrorBoundary} from 'react-error-boundary'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Header from "../../components/styled/Header";
import {useAuth} from "../../hooks/useAuth";
import PersonAddIcon from '@mui/icons-material/PersonAdd';


type views = "suggested" | "accepted" | "declined" | "all"
export default function ApplicationDisplay() {
    const {projectId} = useParams()
    const [title, setTitle] = useState<string>('')
    const [viewType, setVT] = useState<views>("suggested")
    const [applications, setApplications] = useState<Application[]>()
    const [message, setMessage] = useState<undefined | string>()
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        loadApps()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const loadApps = () => {
        getProjectApps(projectId as unknown as number)
            .then((res) => {
                if (isFailRes(res)) {
                    setMessage(res.reason)
                }
                else if (Array.isArray(res) && res.length === 0) {
                    setMessage("No Applications Yet")
                }
                else if (isApplicationArr(res)) {
                    setApplications(res)
                }
            })
            .catch((e) => {showBoundary(e)})
        getProject(projectId as unknown as number)
            .then((res) => {
                if (isProject(res)) {
                    setTitle(res.title)
                }
                else {
                    console.log(res)
                }
            })
            .catch((e) => {showBoundary(e)})
    }
    // TODO: Application selection logic
    if (applications) {
        let applicationList = []
        for (let a of applications) {
            if (viewType !== "all") {
                if (a.status === viewType) {
                    applicationList.push(
                        <Grid item mb={2}>
                            <ApplicationInfo application={a} callBack={loadApps}/>
                        </Grid>
                    )
                }
            }
            else {
                applicationList.push(
                    <Grid item mb={2}>
                        <ApplicationInfo application={a} callBack={loadApps}/>
                    </Grid>
                )
            }
        }
        return (
            <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    <Header color={'primary.main'} mt={3} variant={'h4'}>
                        {title} / Applications
                    </Header>
                </Grid>
                <Grid item xs={6} width={'50%'} mb={2}>
                    <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                </Grid>
                <Grid item mb={2} mt={1}>
                    <Tabs
                        value={viewType}
                        onChange={(e, val) => setVT(val)}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="suggested"
                            label={<Typography variant={'h6'}>Suggested</Typography>} />
                        <Tab value="accepted"
                            label={<Typography variant={'h6'}>Accepted</Typography>} />
                        <Tab value={"hired"}
                             label={<Typography variant={'h6'}>Hired</Typography>}/>
                        <Tab value="declined"
                            label={<Typography variant={'h6'}>Declined</Typography>} />
                        <Tab value={'all'}
                             label={<Typography variant={'h6'}>All</Typography>}/>
                    </Tabs>
                </Grid>
                <Grid xs={6} container direction={'column'} alignItems={'flex-start'} spacing={2} sx={{mt:3}}>
                    {applicationList}
                </Grid>
            </Grid>
        )
    }
    else if (message){
        return (
        <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
            <Grid item mt={3}>
               <Typography variant={'h4'}>{message}</Typography>
            </Grid>
        </Grid>
        )
    }
    else {
        return (<Loading open={true}/>)
    }
}

function ApplicationInfo(props: {application: Application, callBack:Function}) {
    const [conf, setConf] = useState<undefined|ReactNode>(undefined)
    const {showBoundary} = useErrorBoundary()
    const {auth} = useAuth()
    const application = props.application

    const handleResume = async() => {
        try {
            let file = await getResume(application.id as number)
            console.log(file)
            const href = URL.createObjectURL(file as unknown as Blob)
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', application.resume )
            console.log('dl')
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
        catch (e) {
            showBoundary(e)
        }
    }

    const updateStatus = async(status: "accepted" | "declined" | "suggested" | "hired") => {
        setConf(undefined)
        try {
            await updateAppStatus({appid:application.id as unknown as number, status:status })
            props.callBack()
        }
        catch (e) {
            showBoundary(e)
        }
    }
    const triggerAccept = () => {
        const c= <ConfirmDecision
            open={true}
            title="Accept Application?"
            text="Do you want to hire this applicant? This cannot be reversed"
            successCallback={() => {updateStatus("accepted")}}
            cancelCallback={() => {setConf(undefined)}}
            />
        setConf(c)
    }
    const triggerDecline = () => {
        const c= <ConfirmDecision
            open={true}
            title="Decline Applicant?"
            text="Do you want to decline this applicant? This cannot be reversed"
            successCallback={() => {updateStatus("declined")}}
            cancelCallback={() => {setConf(undefined)}}
            />
        setConf(c)
    }
    const triggerSuggest = () => {
        const c= <ConfirmDecision
            open={true}
            title="Suggest Applicant?"
            text="Do you want to suggest this applicant? This cannot be reversed"
            successCallback={() => {updateStatus("suggested")}}
            cancelCallback={() => {setConf(undefined)}}
            />
        setConf(c)
    }

    const triggerHire = () => {
        const c = <ConfirmDecision
            successCallback={() => updateStatus("hired")}
            cancelCallback={() => setConf(undefined)}
            title={"Hire Applicant?"}
            text={"Hiring is not reversible within the system. Do you wish to proceed?"}
            open={true}
            />
        setConf(c)
    }
    let info = [
        {label: "Name", info:application.name},
        {label: "Contact Email", info:application.devEmail},
        {label: "Cover Letter", info:application.coverLetter},
        {label: "Submitted", info:application.submitted}
    ]

    return (
        <InfoDisplay title={application.name} info={info}>
            <Grid
                container direction='column'
                spacing={2} mt={2.5} mb={.5}
                alignItems='center' justifyContent={'flex-start'}
            >
                <Grid item>
                    <Button
                        variant='contained' size='medium'
                        sx={{minWidth:230}} onClick={() => {handleResume()}}
                        startIcon={<DownloadIcon/>}
                    >
                        <Typography>
                            Download Resume
                        </Typography>
                    </Button>
                </Grid>
                {
                    (auth.userType === "admin" && application.status !== "hired")
                    ? ((application.status === "accepted")
                        ? (
                            <Grid item>
                                <Button
                                    variant='contained' size='medium'
                                    sx={{minWidth:230}}
                                    onClick={() => {triggerHire()}}
                                    startIcon={<PersonAddIcon />}
                                >
                                    <Typography >
                                        Hire
                                    </Typography>
                                </Button>
                            </Grid>
                        )
                        : (<Grid item>
                                <Button
                                    variant='contained' size='medium'
                                    sx={{minWidth:230}}
                                    onClick={() => {triggerSuggest()}}
                                    startIcon={<ArrowOutwardIcon />}
                                >
                                    <Typography >
                                        Suggest
                                    </Typography>
                                </Button>
                            </Grid>)
                    ) : undefined
                }
                { (application.status !== "hired")
                ? (<><Grid item>
                    <Button
                        variant='contained' size='medium'
                        sx={{minWidth:230}}
                        onClick={() => {triggerAccept()}}
                        startIcon={<CheckIcon />}
                    >
                        <Typography >
                            Accept
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained' size='medium' color='error'
                        sx={{minWidth:230}}
                        onClick={() => {triggerDecline()}}
                        startIcon={<CloseIcon />}
                    >
                        <Typography>
                            Reject
                        </Typography>
                    </Button>
                </Grid> </>)
                    : undefined
                }
            </Grid>
            {conf}
        </InfoDisplay>
    )
}


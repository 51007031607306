import {Backdrop, CircularProgress, Grid} from "@mui/material";
import Header from "../styled/Header";
export default function Loading(props:{open?:boolean}) {
    return (
        <Backdrop sx={{ color: 'secondary.main', zIndex:10}} open={(props.open?props.open:true)}>
            <Grid container direction='column' justifyContent={'center'} alignItems={'center'} spacing={5}>
                <Grid item>
                    <Header variant='h3' color={'inherit'}>Loading</Header>
                </Grid>
                <Grid item>
                    <CircularProgress color="inherit" />
                </Grid>
            </Grid>
        </Backdrop>
    )
}

import {Dialog, DialogActions, DialogContent, Button, Typography, DialogTitle, DialogContentText} from "@mui/material";


interface props {
    successCallback: (() => {}) | (() => void)
    cancelCallback: (() => {}) | (() => void)
    title: string,
    text: string,
    open: boolean,
}
export default function ConfirmDecision(props:props) {
    return(
        <Dialog open={props.open} onClose={props.cancelCallback}>
            <DialogTitle variant={'h4'} color={'primary.dark'}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText variant={'h6'}>
                    {props.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button size={'large'}
                    onClick={props.cancelCallback}
                    autoFocus
                    color={'error'}
                >
                    Cancel
                </Button>
                <Button size='large' onClick={props.successCallback}>Continue</Button>
            </DialogActions>
        </Dialog>
    )
}

import Form from "../../components/abstract/Form";
import {useState} from 'react'
import { makeAdminAccount } from "../../ApiClient";
import {Grid, Typography, Button} from '@mui/material'
export default function MakeAdmin() {
    const [display, setDisplay] = useState<"form"|"success">("form")
    let inputs = [
        {key: "netid", name:"Netid"},
        {key: "email", name:"Email"},
        {key: "password", name:"Password"}
    ]
    const success = (res:any) => {
        setDisplay("success")
    }

    if (display == "form") {
        return (
            <Form
                header={"Make Admin Account"}
                inputs={inputs}
                handleSuccess={success}
                apiCall={makeAdminAccount}
                specialInputs={[]}
                confDialog={{
                    title:"Make admin account?",
                    text:"This is an irreversible decision"
                }}
            />
        )
    }
    else {
        return (
            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                <Grid item>
                    <Typography variant='h3' color='primary.light'>
                        Admin Account has been created!
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant='outlined' size='medium' onClick={() => {setDisplay('form')}}>
                        <Typography>Go Back</Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

import {Typography, Grid} from '@mui/material'
import {useRouteError, isRouteErrorResponse} from "react-router-dom";

export default function RouteErrorPage(){
    let routeError = useRouteError()

    let title = "Something went wrong!"
    let message = "Sorry, we aren't sure what happened but something just broke."

    if (isRouteErrorResponse(routeError)) {
        if (routeError.status === 404) {
            title = "Page not found!"
            message = "Sorry, we couldn't find the page you were looking for."
        }
    }
    return(
        <Grid
            container item
            direction={'column'}
            justifyContent={"center"}
            alignItems={"center"}
            height={'100%'}
        >
            <Grid item mt={'12%'}>
                <Typography mb={5} color={'error.main'} variant={'h3'} fontWeight={'bold'}>
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={'h6'}>{message}</Typography>
            </Grid>
        </Grid>
    )
}

import {useState, useEffect} from 'react'
import { DataGrid, GridColDef, useGridApiRef, GridCsvExportOptions, GridPrintExportOptions } from '@mui/x-data-grid'
import { getReports, updateReport } from '../../ApiClient'
import Loading from '../../components/statics/Loading'
import {Grid, Typography, IconButton, TextField, Divider, Menu, MenuItem} from '@mui/material'
import Header from '../../components/styled/Header'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {useErrorBoundary} from 'react-error-boundary'
export default function Reports() {
    const [reports, setReports] = useState<Report[]>()
    const today = dayjs().hour(22).minute(58)
    const prevMonth = dayjs().subtract(1, 'month').hour(0).minute(0)
    const [start, setStart] = useState(prevMonth)
    const [end, setEnd] = useState(today)
    const [menuAnchor, setMenuAnchor] = useState<null|HTMLElement>(null)
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        getReports()
            .then((res) => {
                //@ts-ignore
                setReports(res)
            })
            .catch((e) => {showBoundary(e)})
    }, [])
    const apiRef = useGridApiRef()
    const openMenu = Boolean(menuAnchor)
    interface updateReq {
        funding?: Report['funding']
        budget?: Report['budget']
        hourlyRate?: Report['hourlyRate']
    }
    const handleUpdate = async (originalRow: Report, updatedRow:Report) => {
        let toUpdate:updateReq = {}
        if (originalRow.funding !== updatedRow.funding) {
            toUpdate.funding = updatedRow.funding
        }
        if (originalRow.budget !== updatedRow.budget) {
            toUpdate.budget = updatedRow.budget
        }
        if (originalRow.hourlyRate !== updatedRow.hourlyRate) {
            toUpdate.hourlyRate = updatedRow.hourlyRate
        }
        try{
            await updateReport(updatedRow.id, toUpdate)
        }
        catch (e) { showBoundary(e)}
        return updatedRow

    }

    const exportCSV = () => {
        const startStr = start.format("MM-DD-YYYY")
        const endStr = end.format("MM-DD-YYYY")
        const opts:GridCsvExportOptions = {
            allColumns:true,
            delimiter: ',',
            fileName: "CatCoderReports_"+ startStr+'_to_'+endStr
        }
        apiRef.current.exportDataAsCsv(opts)
    }

    const exportPrint = () => {
        const opts:GridPrintExportOptions = {
            allColumns: true,
            hideFooter: true,
            hideToolbar: true,
        }
        apiRef.current.exportDataAsPrint(opts)
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName:"ID", width:30, headerAlign:'left' },
        {field: 'devName', headerName:"Student Name", width:150, headerAlign:'left'},
        {field: 'title', headerName:"Title", width:150, headerAlign:'left'},
        {field: 'devNetid', headerName:"Student Netid", width:150, headerAlign:'left'},
        {field: 'devEmail', headerName:"Student Email", width:150, headerAlign:'left'},
        {field: 'orgName', headerName:"Organizer Name", width:150, headerAlign:'left'},
        {field: 'orgNetid', headerName:"Organizer Netid", width:150, headerAlign:'left'},
        {field: 'orgEmail', headerName:"Organizer Email", width:150, headerAlign:'left'},
        {field: 'organization', headerName:"Organization", width:150, headerAlign:'left'},
        {field: 'funding', headerName:"Funding", width:100, editable:true, headerAlign:'left'},
        {field: 'budget', headerName:"Budget", width:100, editable:true, headerAlign:'left'},
        {field: 'hourlyRate', headerName: "Hourly Rate", width:100, editable:true, headerAlign:'left'},
        {field: 'timestamp', headerName:"Timestamp", width:150, headerAlign:'left'},
    ]

    if (reports) {
        let toShow: Report[] = []
        dayjs.extend(customParseFormat)
        dayjs.extend(isBetween)
        for (let r of reports) {
            const d = dayjs(r.timestamp, "MM/DD/YYYY")
            console.log(r)
            if (d.isBetween(start, end)) { toShow.push(r)}
        }

        return (
            <Grid container direction={'column'} spacing={1} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Grid container item direction='column' alignItems={'center'} justifyContent={'center'}>
                    <Grid item>
                        <Header variant='h4' color={'primary.main'}>
                            Reports
                        </Header>
                    </Grid>
                    <Grid item xs={6} width={'50%'}>
                        <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                    </Grid>
                </Grid>
                <Grid
                    container item direction='row' my={2}
                    justifyContent={'flex-end'} alignItems={'center'}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Typography variant={'h6'} mr={1.5}>Start: </Typography>
                        <MobileDatePicker
                                  inputFormat="MM/DD/YYYY"
                                  value={start}
                                  //@ts-ignore
                                  onChange={(newVal)=>{setStart(newVal)}}
                                  renderInput={(params:any) => <TextField {...params} />}
                                />
                        <Typography variant='h6' ml={3.5} mr={1.5}>End: </Typography>
                        <MobileDatePicker
                                  inputFormat="MM/DD/YYYY"
                                  value={end}
                                  //@ts-ignore
                                  onChange={(newVal)=>{setEnd(newVal)}}
                                  renderInput={(params:any) => <TextField {...params} />}
                                />
                    </LocalizationProvider>
                    <div>
                        <IconButton
                            sx={{ml:3.5}}
                            onClick={(e) => setMenuAnchor(e.currentTarget)}
                            >
                            <SaveAltIcon fontSize={'large'} color={'primary'}/>
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchor} open={openMenu}
                            onClose={() => setMenuAnchor(null)}
                            >
                            <MenuItem onClick={exportCSV}>Export as CSV</MenuItem>
                            <MenuItem onClick={exportPrint}>Print Page</MenuItem>
                        </Menu>
                    </div>
                </Grid>
                <Grid container item mt={1}>
                <DataGrid
                    rows={toShow}
                    columns={columns}
                    autoHeight
                    apiRef={apiRef}
                    processRowUpdate={
                        (updatedRow, originalRow) => handleUpdate(originalRow, updatedRow)
                    }
                    /*sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            whiteSpace: 'break-spaces',
                            lineHeight: 1,
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                            pl: 1,
                        },
                    }}*/
                    />
                </Grid>
            </Grid>
        )
    }
    else {
        return <Loading open={true} />
    }
}

import {Dashboard} from "../Dashboard";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
export default function OrgDashboard() {
    let navMap = [
        {
            name: "My Projects", link:"manage", icon:<LibraryBooksIcon/>,
            helpText: "Click on the dropdown tabs for each project to view the details of" +
                " the project, as well as various management options."
        },
        {
            name: "Submit Proposal", link:"propose", icon: <SendIcon />,
            helpText: "Submit a proposal for a project/job opening. A site admin will review the " +
                "proposal and approve it, after which it will be visible on the Projects page (link" +
                "in the navigation bar at the top of the screen) for all developers to see."
        },
        {name: "Profile", link:"profile", icon: <AccountCircleIcon />,
            helpText: "View/change your profile information and settings. If something can be changed" +
                " there will be an icon of a pencil next to it. Toggleable settings have a switch, and " +
                "the label to the right of the switch indicates the current setting."
        }
    ]
    return (<Dashboard authType={'org'} navMap={navMap} />)
}

import {Dashboard} from "../Dashboard";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InsightsIcon from '@mui/icons-material/Insights';
export default function AdminDashBoard() {
    let navMap = [
        {
            name: "Profile", link:"profile", icon:<AccountCircleIcon />,
            helpText: "View/change your profile information and settings. If something can be changed" +
                " there will be an icon of a pencil next to it. Toggleable settings have a switch, and " +
                "the label to the right of the switch indicates the current setting."
        },
        {
            name: "All Projects", link:"projects", icon:<LibraryBooksIcon />,
            helpText: "Manage all projects. The tabs at the top filter the type of project shown. Within" +
                " these groups they are sorted latest to oldest. Click the dropdown for the project to" +
                " see the management options. Aside from being able to approve/reject projects that " +
                "are pending approval, you can edit the project information, or directly manage it as" +
                " if you were the owner by viewing applications and closing/re-opening it."
        },
        {
            name: "Manage Accounts", link:"accounts", icon:<ManageAccountsIcon />,
            helpText: "View all accounts, with filters based off the type of account (developer," +
                " organizer, admin). By clicking on the dropdown, you can view the account's profile" +
                " information as well as ban them if you wish to deny the user access. Banned users" +
                " can be unbanned the same way."
        },
        {
            name: "Reports", link:"reports", icon:<AssessmentIcon />,
            helpText: "View the report log generated when a developer is hired for a project. By default" +
                " they are rendered newest-oldest, but you can filter them to a timeframe with the selectors" +
                "at the top of the page. If its a university project, edit the funding type, organization," +
                " hourly rate and/or budget by clicking the cell, entering a value, and saving. Except " +
                "for organization these values are blank by default. Press download to save all the reports" +
                "on screen as a csv."
        },
        {
            name: "Usage Stats", link:'statistics', icon:<InsightsIcon/>,
            helpText: "Basic summary of the website statistics."
        },
        {
            name: "Site Settings", link:'settings', icon:<AdminPanelSettingsIcon/>,
            helpText: "Edit certain sitewide settings. Press the pencil icons to edit fields that have them." +
                "Checkboxes and switches will automatically update upon being changed, no save button is " +
                "needed."
        },
        {name: "Make Admin Account", link:'make_admin', icon:<CoffeeMakerIcon/>,
            helpText: "Create a new admin level account with the information given. Note that the" +
                " password cannot be viewed after creation, so you must write down the value entered" +
                " to tell the person who's account you are making. They are able to reset the password"+
                " themselves if need be, and are encouraged to do so. "
        }

    ]
    return (<Dashboard authType={"admin"} navMap={navMap} />)
}



import useSearchNavigate from "./useSearchNavigate";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";

export default function useRedirectAuthSafe() {
    const nav = useNavigate()
    const sNav = useSearchNavigate()
    const {auth} = useAuth()

    return (accType:"dev"|"org"|"admin", authedRedirect:string, searchParamsForLogin:any) => {
        if (auth.userType === accType && auth.netid !== "") {
            nav(authedRedirect)
        }
        else if (auth.userType === undefined) {
            sNav('/login', searchParamsForLogin)
        }
        else {
            nav('/signup')
        }
    }
}
import { useParams, useNavigate } from "react-router-dom";
import Form from "../../components/abstract/Form";
import { PasswordField } from "../../components/abstract/FormSpecials";
import { useState } from "react";
import { forgotPassword } from "../../ApiClient";
import { isSuccessRes } from "../../guards";
import { Typography, Grid, Button } from "@mui/material";
import {useErrorBoundary} from 'react-error-boundary'
export default function ForgotPassword() {
    const {token} = useParams()
    const [password, setPassword] = useState<string>('')
    const [state, setState] = useState<'invalid'|'form'|'success'>('form')
    const nav = useNavigate()
    const {showBoundary} = useErrorBoundary()
    // form with a netid and a 2 password fields w/ logic to ensure they match
    let inputs = [
        {key:'netid', name:'Netid'},

    ]
    let specialInputs = [
        {
            state: password,
            component: <PasswordField value={password} update={setPassword} />,
            key: 'password'
        },
    ]
    const callBack = async (data:{password: string, netid:string}) => {
        let info = {
            password: data.password,
            netid: data.netid,
            token: token as unknown as string
        }
        try{
            let res = await forgotPassword(info)
            if (isSuccessRes(res)){
                setState('success')
            }
            else {
                setState("invalid")
            }
        }
        catch (e) {
            showBoundary(e)
        }
    }

    if (state === "form") {
        return(
            <Form
                header={"Reset Password"}
                inputs={inputs}
                callBack={callBack}
                specialInputs={specialInputs}
                handleSuccess={()=>{}}
                confDialog={{title: "Confirm Reset?", text:''}}
            />
        )
    }
    else if (state === "success") {
        return (
            <Grid container direction='column' spacing={2} alignItems={'center'} justifyContent='center'>
            <Grid item>
                <Typography variant='h3' color='primary.light'>
                    Your password has been reset!!
                </Typography>
            </Grid>
            <Grid item mt={3}>
                <Button size="large" variant='contained' onClick={()=>{nav('/login')}}>
                    <Typography>LOGIN</Typography>
                </Button>
            </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Grid container direction='column' spacing={2} alignItems={'center'} justifyContent='center'>
            <Grid item>
                <Typography variant='h3' color='primary.light'>
                Invalid request, either your link was invalid or the wrong netid was entered.
                Please try again
                </Typography>
            </Grid>
            <Grid item mt={3}>
                <Button size="large" onClick={()=>{nav('/trigger_reset')}}>
                    <Typography><u>Re-send Reset Email</u></Typography>
                </Button>
            </Grid>
            </Grid>
        )
    }
}

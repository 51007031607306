import {Grid, Button, Typography} from '@mui/material'
import { useErrorBoundary } from 'react-error-boundary'
import Header from "../components/styled/Header";
import {isAxiosError} from 'axios'

export default function AccountErrorPage({error}: {error:Error}) {
    const { resetBoundary } = useErrorBoundary()

    let title = error.name
    let message = error.message
    if (isAxiosError(error) && error.response) {
        title = "Error " + error.response.status + ': ' + error.response.statusText
    }
    else if (isAxiosError(error)) {
        title = "Error 503: Connection Timed Out"
        message = "The server couldnt be accessed, either because your connection has expired or because something is preventing your connection"+ 
        " to us. Please retry your action, if the error persists, we are either experiencing an outage, performing maintainance, or you have a "+
        "connectivity issue. "
    }

    return (
        <Grid
            container direction={'column'}
            spacing={4}
            justifyContent={'center'} alignItems={'center'}
        >
            <Grid container item justifyContent={'center'} alignItems={'center'} spacing={4}>
                <Grid item mt={10} mb={4} xs={8}>
                    <Header variant={'h2'} color={'error.main'} textAlign={'center'}>
                        {title}
                    </Header>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant={'h5'} textAlign={'center'}>
                        {message}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                    <Button variant={'contained'} color={'error'} size='large' onClick={resetBoundary}>
                        <Typography variant={'h6'}> Retry</Typography>
                    </Button>
            </Grid>
        </Grid>
    )
}

import {AppBar, Typography, IconButton, Box, Toolbar, Menu, MenuItem, Button, Grid} from '@mui/material'
import {useState, MouseEvent} from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import {useNavigate} from "react-router-dom";
import CemsLogo from "../assets/2017_Sec_Logos_EngMath_H_K.png"
export default function Navbar() {
    const navigate = useNavigate()

    // TAKEN FROM EXAMPLE ON MUI SITE
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

    // triggers taken from mui tutorial
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const isSelected = (link:string) => {
        let path = window.location.pathname
        if (path === link) { return true}
        let pathSplit = path.split('/')
        let acclist = ["dev", "org", "admin"]
        let linkSplit = link.split('/')
        let accInLink = false
        for (let s of linkSplit) {
            if (acclist.includes(s)) { accInLink = true}
        }
        if (accInLink) {
            for (let s of pathSplit) {
                if (acclist.includes(s)) {
                    return true
                }
            }
        } else {
            return false
        }
    }
    let pages = [
        {label:"Home", link:"/home"},
        {label:"Projects", link:"/projects"},
        {label:"Contact", link:"/contactus"}
    ]
    let reAuthKey = sessionStorage.getItem('reAuthKey')
    if (reAuthKey) {
        // @ts-ignore
        for(let i in pages) {
            if (pages[i].label === "Login"){
                // @ts-ignore
                pages.splice(i, 1)
            }
        }
        let parts = reAuthKey.split(":") // splits into 4 parts because the apitoken also has a colon separator
        let userType = parts[0].split("").reverse().join("")
        let netid = parts[1].split("").reverse().join("")
        pages.push({label:"Dashboard", link: '/'+userType+'/'+netid+'/'})
        pages.push({label:"Logout", link: '/logout'})
    }
    else {
        for(let i in pages) {
            if (pages[i].label === "Logout" || pages[i].label === "Dashboard"){ // @ts-ignore
                pages.splice(i, 1)}
        }
        pages.push({label:"Login", link:'/login'})
    }

    return(
        <AppBar position="static" sx={{justifyContent:'center', mb:3, pt:.5, height:75, spacing:0}}>
            <Toolbar disableGutters={true}>
                {/* NORMAL VERSION */}
                <Box sx={{ flexGrow:1, display: {xs:'none', md:'flex'}}}>
                    <Box
                        component={'img'}
                        src={CemsLogo} alt={'cems logo'}
                        height={55} width={'auto'}
                        display={{xs:'none', md:'flex'}}
                        pb={1} ml={2}
                    />
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Grid container direction='row' item alignItems={'center'} xs={3} pl={2.5}>
                        <Grid item>
                            <Typography
                            noWrap variant={'h4'} color={'secondary.main'}
                            sx={{
                                display:{xs:'none', md: 'flex' },
                                textDecoration: 'none',
                                letterSpacing: '.3rem',
                                fontWeight:'bold',
                                pb:.4
                            }}
                            fontFamily={'Oswald'}
                            >
                                CATCODERS
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container direction={'row'}
                        alignItems={'center'} item
                        justifyContent={'flex-end'}
                        xs={9} pr={3.5}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.label}
                                sx={{ ml:1.5, color: 'white', pt:1,
                                    display: 'block', textTransform:'none',
                                }}
                                onClick={()=>{navigate(page.link)}}
                            >
                                <Typography
                                    sx={{letterSpacing:'.1rem', "&:hover": {color:'secondary.dark'}}}
                                    color={(isSelected(page.link))? 'secondary.dark':'white'}
                                    variant={'h6'}>
                                    {page.label}
                                </Typography>
                            </Button>
                        ))}
                    </Grid>
                    </Grid>
                </Box>

                {/* MOBILE VERSION */}
                <Typography
                    noWrap variant={'h6'} color={'secondary.main'}
                    sx= {{
                    display:{xs:'flex', md: 'none' },
                    textDecoration: 'none',
                    letterSpacing: '.1rem',
                    mr:2,
                    fontWeight:'bold'
                }}>
                    CATCODERS
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                      size="large"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: 'block', md: 'none' },
                      }}
                    >
                      {pages.map((page) => (
                        <MenuItem key={page.label} onClick={()=>{
                            handleCloseNavMenu()
                            navigate(page.link)
                        }}>
                          <Typography textAlign="center">{page.label}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

import {Divider, Grid, Typography} from "@mui/material";
import EditableInfo from "../../components/abstract/EditableInfo";
import {useState, useEffect} from 'react'
import { getSiteSettings, setContactEmail, updateNotifyMe } from "../../ApiClient";
import SettingSwitch from "../../components/abstract/SettingsSwitch";
import Bolded from "../../components/styled/Bolded";
import Loading from "../../components/statics/Loading";
import Header from "../../components/styled/Header";
import {useErrorBoundary} from 'react-error-boundary'
interface Settings {
    contactEmail: string,
    notifyMe: boolean,
}
const isSettings = (a:any): a is Settings => {return (a as Settings).contactEmail !== undefined}

export default function SiteSettings() {
    const [settings, setSettings] = useState<Settings>()
    const [message, setMessage] = useState<string>()
    const {showBoundary} = useErrorBoundary()
    let docs = 'http://amenezes.w3.uvm.edu/site'
    let repo = 'https://gitlab.uvm.edu/catcoders-connect-dev/catcoders-laravel/'
    useEffect(() => {
        getSettings()
    }, [])
    const getSettings = () => {
        getSiteSettings()
            .then((res) => {
                if (isSettings(res)) {
                    setSettings(res)
                }
            })
            .catch((e) => {showBoundary(e)})
    }
    const updateContactEmail = async (newVal:string) => {
        try {
            await setContactEmail(newVal)
            setMessage("Contact form recipient email updated")
        }
        catch(e){
            showBoundary(e)
        }
    }
    const handleUpdateNotifyMe = async () => {
        try {
            //@ts-ignore
            await updateNotifyMe(!settings.notifyMe)
        }
        catch (e) {
            showBoundary(e)
        }
    }
    if (settings) {
        return (
            <Grid
                container xs={10} direction={"column"}
                alignItems={'center'} ml={3}
                justifyContent={'center'} spacing={1}>
            <Grid item>
                <Header variant={'h4'} color='primary.main' textAlign={'center'} mt={3} >
                    Admin Settings
                </Header>
            </Grid>
            <Grid item xs={6} width={'50%'} mb={2}>
                <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
            </Grid>
            <Grid container item direction={'column'} alignItems={'flex-start'}>
                <Grid item mt={2}>
                    <Typography variant='h5' color={'primary.light'}>
                        {message}
                    </Typography>
                </Grid>
                <Grid item>
                    <EditableInfo
                        callback={updateContactEmail}
                        label={"Contact form reciever email"}
                        info={settings.contactEmail}
                        />
                </Grid>
                <Grid item>
                    <SettingSwitch
                        callback={handleUpdateNotifyMe}
                        ogVal={settings.notifyMe}
                        label={"Notify me when projects are proposed"}
                        />
                </Grid>
                <Grid item mt={2}>
                    <Typography variant='h5' textAlign={'left'}>
                        <Bolded>Site Documentation:</Bolded><a href={docs}>{docs}</a>
                    </Typography>
                </Grid>
                <Grid item mt={2}>
                    <Typography variant='h5' textAlign={'left'}>
                        <Bolded>Site Repository: </Bolded>
                        <a rel="noopener" target="_blank" href={repo}>{repo}</a>
                    </Typography>
                </Grid>
            </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Loading />
        )
    }

}



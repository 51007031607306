import {Avatar, Grid, Typography, Box} from "@mui/material";
import {List, ListItem, useScrollTrigger, Fade, Fab} from "@mui/material";
import CCWorkflowChart from "../assets/CC Workflow overview v2.svg"
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import CampusGreen from "../assets/CampusGreenEdited2_comp.jpg"
import Computer from "../assets/computer_comp.jpg"
import StudentWorking from "../assets/studentworking_comp.jpg"
import DevTL from "../assets/Developer tl.svg"
import InfoCard from "../components/statics/InfoCard"
import {useRef} from "react";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import Header from '../components/styled/Header'

export default function Home() {
    const processRef = useRef<null| HTMLDivElement>(null)
    const topRef = useRef<null | HTMLDivElement>(null)
    const trigger = useScrollTrigger({
        target: window,
        disableHysteresis: true,
        //@ts-ignore
        threshold: window.innerHeight * 2.5,
    })
    const scrollToLearnMore = () => {
        // @ts-ignore
        processRef.current.scrollIntoView({behavior:"smooth"})
    }
    const scrollToTop = () => {
        topRef.current?.scrollIntoView({behavior:"smooth"})
    }
    return(
        <Box sx={{flexGrow: 1, mt:-3}}>
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={0}>
            {/* Banner and card infotip */}
            <Grid container direction='row' spacing={0} item ref={topRef}
            sx={{
                display: {xs:"none", sm:"flex"},
                alignItems: "center",
                justifyContent: "flex-start",
                width:'100%',
                height:'100%',
                flexDirection: "row",
                backgroundImage: `url(${CampusGreen})`,
                backgroundSize: "100%",
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
            }}>
                <Grid container item >
                {/* The left margin moves the card inward, the top/bottom margins expand the image fully */}
                    <Grid item ml={'10%'} mb={'10%'} mt={'10%'}>
                        <InfoCard scroll={scrollToLearnMore}/>
                    </Grid>
                </Grid>
            </Grid>

            {/* Main header/tagline */}
            <Grid item mt={4} mb={2}>
                <Header variant={'h2'} color={'primary.main'} mt={2}>
                    Welcome to UVM CatCoders
                </Header>
            </Grid>

            {/* overview blurb */}
            <Grid container justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={6} mt={4} mb={8}>
                    <Typography variant={'h6'} fontStyle={'italic'} textAlign={'center'}>
                        CatCoders facilitates connections between UVM students with software development
                        skills and stakeholders in the broader research and business community seeking
                        employees. It provides students with opportunities to gain experience with
                        real-world projects and get paid. It allows stakeholders to
                        engage with a talented student body possessing current and applicable skillsets
                        in software development.
                    </Typography>
                </Grid>
            </Grid>

            {/* boxes */}
            <Grid
                container direction={'row'} item xs={6}
                spacing={0} justifyContent={'space-evenly'}
                alignItems={'stretch'} mt={3} mb={3}
            >
                {/* Organizer */}
                <Grid container direction={'column'} item xs={4}
                        spacing={2} justifyContent={'center'} alignItems={'center'}
                        sx={{border:2, borderColor:'primary.dark',borderRadius:3}}
                >
                    <Grid item>
                        <Avatar alt="Organizer" sx={{bgcolor:'secondary.main', width:50, height:50}}>
                            <CorporateFareOutlinedIcon fontSize={'large'} htmlColor={"#000000"}/>
                        </Avatar>
                    </Grid>
                    <Grid item mb={1}>
                        <Header variant={'h4'} color={'primary.main'}>Organizers</Header>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} textAlign={'center'} mb={2} mr={1}>
                            Post your open positions for UVM's talented student developers, who have
                            through the years have filled over a hundred positions via CatCoders
                        </Typography>
                    </Grid>
                </Grid>

                {/* Developer */}
                <Grid container direction={'column'} item xs={4}
                        spacing={2} justifyContent={'center'} alignItems={'center'}
                        sx={{border:2, borderColor:'primary.dark',borderRadius:3}}
                >
                    <Grid item>
                        <Avatar alt="Developer" sx={{bgcolor:'secondary.main', width:50, height:50}}>
                            <TerminalOutlinedIcon fontSize={'large'} htmlColor={"#000000"}/>
                        </Avatar>
                    </Grid>
                    <Grid item mb={1}>
                        <Header variant={'h4'} color={'primary.main'}>Students</Header>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} textAlign={'center'} mb={2} mr={1}>
                            Apply to positions from employers around the country,
                            building your skills with real-world experience and adding to your resume.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {/* Images and descriptions */}
            <Grid
                container direction={'row'} item xs={8}
                spacing={0} justifyContent={'space-evenly'}
                alignItems={'stretch'} mt={6} mb={7}
                sx={{backgroundColor:'secondary.dark'}}
            >
                <Grid container direction={'column'} item xs={5}
                    spacing={2} justifyContent={'flex-start'} alignItems={'center'}
                    mt={7} mb={7} mr={1.5}
                >
                    <Grid item>
                        <Box component={'img'} src={Computer} alt={'computer'} width={'100%'} height={'100%'}/>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant={'h4'} mb={1} mt={2}
                            color='primary.dark' fontWeight={650}
                            fontFamily={'oswald'} letterSpacing={'.1rem'}
                        >
                            Resources
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} textAlign={'center'}>
                            UVM offers our students resources such as webhosting services for university-affiliated projects,
                            the 'Deep Green' supercomputer, and a suite of cutting edge software tools.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction={'column'} item xs={5}
                    spacing={2} justifyContent={'flex-start'} alignItems={'center'}
                    mt={7} mb={7} ml={1.5}
                >
                    <Grid item>
                        <Box component={'img'} src={StudentWorking} alt={'student'} width={'100%'} height={'100%'}/>
                    </Grid>
                    <Grid item>
                        <Header
                            variant={'h4'} mt={2} mb={1}
                            color='primary.dark' fontWeight={650}
                            fontFamily={'oswald'} letterSpacing={'.1rem'}
                        >
                            Our Students
                        </Header>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h6'} textAlign={'center'}>
                            We boast a talented pool of developers studying in the College of
                            Engineering and Mathematics, working with a myriad of technologies
                            under excellent professors.
                        </Typography>
                    </Grid>
                    <Typography ref={processRef}></Typography>
                </Grid>
            </Grid>

            {/* How to section */}
            <Grid item mb={4}>
                <Header
                    variant={'h3'}
                    color={'primary.dark'}
                    fontWeight={500}
                >The Process</Header>
            </Grid>

            {/* organizer flowchart */}
            <Grid container item xs={8}
                  direction={'column'} mb={7} pl={3}
                  spacing={4} alignItems={'flex-start'}
            >
                <Grid item ml={5}>
                    <Typography variant={'h4'} fontWeight={350}>Organizer Timeline</Typography>
                </Grid>
                <Grid container item justifyContent={'center'} alignItems={'center'} >
                    <Grid item xs={8}>
                        <Box
                            component={'img'}
                            alt={'workflow chart'}
                            src={CCWorkflowChart}
                            sx={{maxWidth:'100%'}}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Typography component={'div'} variant={'h6'} fontWeight={300} sx={{spacing:2}}>
                        {/* @ts-ignore */}
                        <List sx={{ pl:3}}>
                            <ListItem >
                                1.&nbsp; Submit a proposal for your project/position in the Submit Proposal tab of your
                                Dashboard.
                            </ListItem>
                            <ListItem>
                                2.&nbsp; Within 5 business days, an admin will approve or request changes to the
                                project. You may make the changes and resubmit, after which it will
                                be approved.
                            </ListItem>
                            <ListItem>
                                3.&nbsp; Upon approval, student developers will be able to see and apply for the
                                open position with a cover letter and resume.
                            </ListItem>
                            <ListItem>
                                4.&nbsp; In the My Projects tab of your Dashboard, click on the dropdown
                                for your project and then click the View Applications button to
                                see all applications for the position. Clicking on each application
                                allows you to view the developer's information, download their resume,
                                and then approve or reject their application. You may approve as many
                                applications as you wish.
                            </ListItem>
                            <ListItem>
                                5.&nbsp; Once your position(s) have been filled for the project, set it closed in
                                the project menu with the Close Project button.
                            </ListItem>
                        </List>
                    </Typography>
                </Grid>
            </Grid>

            {/* developer flowchart */}
            <Grid container item xs={8}
                  direction={'column'} mb={10} pl={3}
                  spacing={4} alignItems={'flex-start'}
            >
                <Grid item ml={5}>
                    <Typography variant={'h4'} fontWeight={350}>Developer Timeline</Typography>
                </Grid>

                <Grid container item justifyContent={'center'} alignItems={'center'} >
                    <Grid item xs={8}>
                        <Box
                            component={'img'}
                            alt={'workflow chart'}
                            src={DevTL}
                            sx={{maxWidth:'100%'}}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    <Typography component={'div'} variant={'h6'} fontWeight={300} sx={{spacing:2}}>
                        <List sx={{ pl:3}}>
                            <ListItem >
                                1.&nbsp; Create your account with CatCoders using your UVM email address
                            </ListItem>
                            <ListItem>
                                2.&nbsp; Click the Projects button in the navigation bar at the top of the
                                screen to see a list of open projects. Click on the dropdown
                                to see the full project/position description; including funding, application deadline
                                the employer's contact information, and the funding for the project. If
                                you see one that interests you, click the "Apply" button to submit an
                                application.
                            </ListItem>
                            <ListItem>
                                3.&nbsp; The employer will notified of your application by our system, and will
                                review all applications to their position according to their process. You can check the
                                status of your application by going to your Dashboard and clicking the "My
                                Applications" tab, then clicking the dropdown with the title of the position
                                you applied to. In the expanded tab will be a line indicating the status
                                of your application.
                            </ListItem>
                            <ListItem>
                                4.&nbsp; If your application is selected by the employer, you will recieve a
                                notification from our systems. Some employers may choose to contact you directly
                                for follow ups interviews after you apply, so be sure to keep an eye on your
                                inbox for external emails.
                            </ListItem>
                        </List>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>

        {/* Scroll to top button*/}
        <Fade in={trigger}>
            <Box
                onClick={scrollToTop}
                role="presentation"
                sx={{
                    position: 'fixed',
                    top: '4vh', left:'50%',
                    transform: `translate(-50%, -50%)`,
                    display: {
                        xs:'none',
                        md:'flex'
                    }
                }}
            >
                <Fab variant="extended" color="primary" size='medium'>
                    <KeyboardArrowUpSharpIcon />
                    <Typography> &nbsp; To Top</Typography>
                </Fab>
            </Box>
        </Fade>
        </Box>
    )
}


import {deleteAccount, getAccounts, setUserStatus, adminResetPassword} from "../../ApiClient";
import InfoDisplay from "../../components/abstract/InfoDisplay";
import {useState, useEffect} from 'react'
import { Box, Grid, Tabs, Tab, IconButton, Snackbar, Divider } from "@mui/material";
import TextField from '@mui/material/TextField'
import ConfirmDecision from "../../components/abstract/ConfirmDecision";
import CloseIcon from '@mui/icons-material/Close'
import Loading from "../../components/statics/Loading";
import Header from "../../components/styled/Header";
import {useErrorBoundary} from 'react-error-boundary'
import {Dialog, DialogActions, DialogContent, Button, Typography, DialogTitle, DialogContentText} from "@mui/material";

interface Account {
    netid:string,
    email:string,
    userType: accountTypes
    name: string,
    banned:boolean
}
type accountTypes = "dev" | "org" | "admin"
const isAccountArr = (a:any): a is Account[] => {return (a as Account[])[0].netid !== undefined}

export default function ManageAccounts() {
    const [accounts, setAccounts] = useState<Account[]>()
    const [accType, setType] = useState<accountTypes>("dev")
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        getAccounts()
            .then((res) => {
                if (isAccountArr(res)) {
                    setAccounts(res)
                }
            })
            .catch((e) => {showBoundary(e)})
    }, [])
    const handleSelect = (event:any, val:accountTypes) => {
        setType(val)
    }
    if (accounts) {
        let display = [];
        for (let a of accounts) {
            if (a.userType === accType) {
                display.push(<AccountDisplay a={a} />)
            }
        }

        return (
            <Box sx={{flexGrow: 1}}>
                <Grid container direction={'column'} alignItems={'center'} spacing={2} justifyContent={'flex-start'}>
                    <Grid container
                          direction={'column'}
                          spacing={0}
                          alignItems={'center'}
                          justifyContent={'center'}
                          mt={2} mb={1}
                    >
                        <Grid item>
                            <Header color={'primary.main'} variant='h4'>Accounts</Header>
                        </Grid>
                        <Grid item xs={6} width={'50%'}>
                            <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                        </Grid>
                    </Grid>
                    <Grid item mb={2}>
                            <Tabs
                                value={accType}
                                onChange={handleSelect}
                                textColor="primary"
                                indicatorColor="primary"
                                aria-label="secondary tabs example"
                            >
                            <Tab value="dev"
                                label={<Typography variant={'h6'}>Developers</Typography>} />
                            <Tab value="org"
                                label={<Typography variant={'h6'}>Organizers</Typography>} />
                            <Tab value="admin"
                                label={<Typography variant={'h6'}>Admins</Typography>} />
                        </Tabs>
                    </Grid>
                    <Grid container direction='column' spacing={2} alignItems={'flex-start'} xs={6} mt={2}>
                        {display}
                    </Grid>
                </Grid>
            </Box>
        )
    }
    else {
        return (
            <Loading open={true}/>
        )
    }
}





function AccountDisplay(props:{a:Account}) {
    const [dialog, setDialog] = useState(false)
    const [delDialog, setDD] = useState(false)
    const [alert, setAlert] = useState(false)
    const [b, setB] = useState(props.a.banned)
    const [d, setD] = useState(false)
    const [resetConf, setRC] = useState(false)
    const {showBoundary} = useErrorBoundary()
    let a = props.a
    let info = [
        {label:"Email", info: a.email},
        {label:"Name", info: a.name},
        {label:"Status", info: (b? "Banned" : "Active")}
    ]
    const ban = async() => {
        try {
            //apicall
            setDialog(false)
            await setUserStatus(a.netid, "ban")
            setAlert(true)
            setB(true)
        }
        catch (e) {showBoundary(e)}
    }
    const unban = async() => {
        try {
            setDialog(false)
            //apicall
            await setUserStatus(a.netid, "unban")
            setAlert(true)
            setB(false)
        }
        catch (e) {showBoundary(e)}
    }
    const del = async() => {
        setDD(false)
        try {
            await deleteAccount(a.netid)
            setD(true)
        }
        catch (e) {
            showBoundary(e)
        }
    }
    let label, title, f, text;
    if (b === true) {
        label = "Unban"
        title = "Unban User?"
        text = "This can be reversed"
        f = unban
    } else {
        label = "Ban"
        title = "Ban User?"
        text = "This can be reversed"
        f = ban
    }
    return (
        <Grid item mt={1} >
        <InfoDisplay title={a.netid} info={info}>
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <Button
                        variant='contained'
                        size='small' onClick={() =>setDialog(true)}
                        color={(b?'primary':'error')} sx={{mt:1.25}}
                        disabled={(a.userType==="admin")}
                        >
                        <Typography>{label}</Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={'contained'} size={'small'}
                        onClick={() => {setRC(true); console.log('resetClick')}}
                    >
                        Reset Password
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant={'contained'} size={'small'}
                        onClick={() =>{setDD(true)}}
                        color={'error'} disabled={(d || a.userType==="admin")}
                    >
                        Delete Account
                    </Button>
                </Grid>
            </Grid>
        </InfoDisplay>
        <ConfirmDecision
            successCallback={f} cancelCallback={() => {setDialog(false)}}
            title={title} text={text} open={dialog}
            />
        <ConfirmDecision
            successCallback={del} cancelCallback={() => {setDD(false)}}
            title={"Delete Account?"} text={"This action is irreversible"} open={delDialog}
            />
        <ResetModal open={resetConf} setOpen={setRC} netid={a.netid} />
        <Snackbar
                open={alert}
                onClose={() => {setAlert(false)}}
                message="Setting Updated!"
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={<IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setAlert(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                  }
            />
        </Grid>
        )
}


function ResetModal(props: {open:boolean, setOpen:Function, netid:string}) {
    type contentTypes = "pw" | "success"
    const [contentMode, setCM] = useState<contentTypes>("pw")
    const [pw, setPW] = useState<string>('')
    const {showBoundary} = useErrorBoundary()
    const [errMSG, setMSG] = useState<string>("")
    const reset = async() => {
        try {
            if (pw == undefined || pw.length <8) {
                setMSG("Invalid Password Entry, passwords must be 8 characters")
            }
            else {
                await adminResetPassword(props.netid, pw)
                setCM("success")
            }
        }
        catch (e) { showBoundary(e)}
    }

    return (
        <Dialog open={props.open} onClose={() => {props.setOpen(false)}}>
            <DialogTitle variant={'h4'} color={'primary.dark'}>Force Reset this user's Password?</DialogTitle>
            <DialogContent>
                <DialogContentText variant={'h4'}>
                    User: {props.netid}
                </DialogContentText>
                <DialogContentText variant={'h4'} color={'error'}>
                    {errMSG}
                </DialogContentText>
                {   (contentMode === "pw") ?
                    <><DialogContentText variant={'h6'}>
                    If you wish to reset this user's password, enter the new password in the field below
                    </DialogContentText>
                    <TextField
                        variant={'outlined'}
                        label={'New Password'}
                        fullWidth
                        value={pw}
                        onChange={(e:any)=> {
                            setPW(e.target.value)
                        }}
                        sx={{mb:2, width:500}}
                        />
                    </>
                    : <DialogContentText variant={'h4'} color={'primary'}>
                        Password successfully reset - {pw} - please copy it down then close the window
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                {
                    (contentMode === "pw")
                    ? <><Button size={'large'}
                            onClick={() => {
                                setCM("pw")
                                setPW("")
                                props.setOpen(false)
                            }}
                            autoFocus
                            color={'error'}
                        >
                            Cancel
                        </Button>
                        <Button size='large' onClick={() => reset()}>Continue</Button>
                    </>
                    : <Button size={'large'}
                            onClick={() => {
                                setCM("pw")
                                setPW("")
                                props.setOpen(false)
                            }}
                            autoFocus
                            color={'primary'}
                        >
                            Close
                        </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

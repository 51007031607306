import {Outlet} from "react-router-dom";
import {
    Badge, Grid, IconButton, Drawer, Box, List, Typography,Button, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Divider, Dialog, Card
} from '@mui/material'
import {useAuth} from "../hooks/useAuth";
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import {ReactElement, useEffect, useState} from "react";
import SideNavBar from "../components/abstract/SideNavBar";
import { deleteNotif, deleteUserNotifs, getNotifications } from "../ApiClient";
import { isNotificationArr } from "../guards";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {ErrorBoundary} from 'react-error-boundary'
import AccountErrorPage from "./AccountErrorPage";
import {useErrorBoundary} from 'react-error-boundary'

// Base Dashboard component for the user interface
type DashProps = {
    authType:"org"|"dev"|"admin",
    navMap: {name:string, link:string, icon:ReactElement | undefined, helpText?:string | undefined}[]
}
export function Dashboard(props: DashProps ) {
    // AUTHENTICATION
    // loads the auth state and the requested user id
    const {auth, setAuth} = useAuth()
    const nav = useNavigate()
    const location = useLocation()
    let {netid} = useParams()
    let toNav = {netid: netid, userType: auth.userType}
    let doNavCatch = false
    useEffect(() => {
        const authTypes = ['org', 'dev', 'admin']
        //stored account type
        let userType = auth.userType
        console.log(auth)

        // triggered if the internal auth state has been wiped
        if (userType === undefined) {
            console.log('undef type')
            //rehydrate from persisted authentication key
            let reAuthKey = sessionStorage.getItem('reAuthKey')
            // if auth key exists, the session should be re-instantiated internally (this behavior can be extended with time based authentication in the future
            if (reAuthKey){
                let parts = reAuthKey.split(":") // splits into 4 parts because the apitoken also has a colon separator
                let userType = parts[0].split("").reverse().join("")
                let netid = parts[1].split("").reverse().join("")
                // get clean token
                let token = sessionStorage.getItem('token')

                if (userType === props.authType) {
                    // @ts-ignore
                    setAuth({userType:userType, netid:netid, token:token})
                    toNav.netid = netid
                    toNav.userType = userType
                    doNavCatch = true
                }
                else if (authTypes.includes(userType)) {
                    // @ts-ignore
                    setAuth({userType:userType, netid:netid, token:token})
                    doNavCatch = true
                }
                else {nav('/login')}
            }

            // if the auth key has been wiped, user has been logged out
            else {nav('/login')}
        }

        // triggered if netid or usertype dont match the internal token
        if (userType !== props.authType) {nav('/'+userType+'/'+auth.netid+'/')}
        else if (netid !== auth.netid) {nav('/'+userType+'/'+auth.netid+'/')}

    }, [])
    // catches the Auth context update and forwards user through the gateway
    useEffect(() => {
        if (doNavCatch) {
            console.log(window.location.href)
            let endURL = window.location.href.split(toNav.netid as unknown as string)[1]
            console.log(endURL)
            nav(location)
        }
    }, [auth])

    return(
        <Grid container direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'} >
            <Grid item xs={3}>
                <SideNavBar navMap={props.navMap}/></Grid>
            <Grid item xs={8}>
                <ErrorBoundary
                    FallbackComponent={AccountErrorPage}
                    onError={(e) => console.error(e)}
                >
                <Outlet />
                </ErrorBoundary>
            </Grid>
            <Grid container item direction={'column'} xs={1} alignItems={'center'}>
                <Grid item>
                    <Notifs />
                </Grid>
                <Grid item>
                    <Helper navMap={props.navMap}/>
                </Grid>
            </Grid>
        </Grid>
    )
}


function Notifs() {
    const [show, setShow] = useState(false)
    const [notifs, setNotifs] = useState<Notification[]>()
    // show the button or a "no notifications" text
    const [button, setButton] = useState(false)
    const {showBoundary} = useErrorBoundary()
    const {netid} = useParams()

    // loads data
    useEffect(() => {
        getNotifs()
    }, [])

    // schedules api call to update data by interval
    useEffect(() => {
        const interval = setInterval(() => {
            getNotifs()
        }, 60000)
        return () =>  clearInterval(interval)
    }, [])

    // switches from button to no notifications text if the drawer is cleared or there are no notifs
    useEffect(() => {
        if (notifs && notifs.length > 0) {
            setButton(true)
        }
        else {
            setButton(false)
        }
    }, [notifs])

    const getNotifs = () => {
        getNotifications(netid as unknown as string)
            .then((res) => {
                if (Array.isArray(res) && res.length > 0 && isNotificationArr(res)) {
                    setNotifs(res)
                }
            })
            .catch((e) => {showBoundary(e)})
    }
    const clearNotif = async (notifid:number) => {
        // api delete request .then reset list
        const newList = notifs?.filter((n) => n.id !== notifid);
        setNotifs(newList)
        try {
            await deleteNotif(notifid.toString())
        }
        catch (e) {
            showBoundary(e)
        }
    }
    const clearAll = async() => {
        setShow(false)
        setNotifs([])
        try {
            await deleteUserNotifs(netid as unknown as string)
        }
        catch (e) {showBoundary(e)}

    }
    let num, toShow;
    if (button) {
        num=notifs?.length
        toShow = <Button variant='contained' sx={{width:'50%'}} onClick={() => {clearAll()}}>
                    <Typography>Clear All</Typography>
                </Button>
    }
    else {
        num=0
        toShow = <Typography variant='h6'>No New Notifications</Typography>
    }
    return (
        <Box sx={{display:{xs:'none', md:'flex'}}}>
        <IconButton aria-label="notifications" onClick={() => {setShow(true)}}>
        <Badge badgeContent={num} color="primary">
            <NotificationsIcon fontSize='large'/>
        </Badge>
        </IconButton>
        <Drawer
            open={show} onClose={() => {setShow(false)}}
            anchor='right' PaperProps={{sx:{width:'25%'}}}
        >
            <List subheader={
                <Typography
                    variant={'h5'}
                    textAlign={'center'}
                    mt={.5}
                    fontWeight={550}
                    color='primary.dark'
                >
                    Notifications
                    <Divider variant='middle' sx={{my:1}}/>
                </Typography>
            }>
            {notifs?.map((n, index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButton sx={{maxWidth:50, mr:2}} onClick={() => {clearNotif(n.id)}}>
                        <ListItemIcon>
                            <CloseIcon fontSize="small"/>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemText primary={
                        <Typography variant='h6'>{n.text}</Typography>
                    }/>
                </ListItem>
            ))}
            </List>
            <Grid container direction='column' alignItems={'center'} mt={3}>
                {toShow}
            </Grid>
        </Drawer>
        </Box>
    )
}


interface helperProps {
    navMap: DashProps["navMap"]
}
function Helper(props:helperProps) {
    const [show, setShow] = useState(false)
    const [item, setItem] = useState<number>(0)
    let e = props.navMap[item]

    const handleOpen = () => {
        let i = 0
        for (let e of props.navMap){
            let url = window.location.pathname
            let split = url.split('/')
            if (split[(split.length-1)] === e.link) {
                setItem(i)
            }
            i += 1
        }
        setShow(true)
    }
    const forward = () => {
        if ((item + 1) >= props.navMap.length) {
            setItem(0)
        }
        else {
            setItem(item+1)
        }
    }
    const backward = () => {
        if ((item -1 ) < 0) {
            setItem((props.navMap.length - 1))
        } else {
            setItem(item-1)
        }
    }
    return (
    <Box sx={{display:{xs:'none', md:'flex'}}}>
        <IconButton onClick={() => handleOpen()}>
            <QuestionMarkIcon fontSize={'large'}/>
        </IconButton>
        <Dialog
            open={show}
            onClose={() => {setShow(false)}}
            sx={{
                '& .MuiDialog-container': {
                    alignItems: "flex-start"
                }
            }}
        >
            <Card >
                <Grid container direction={'column'}
                      spacing={3} justifyContent={'flex-start'}
                      alignItems={'center'} p={3} mt={1}
                >
                    <Grid
                        container direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        spacing={2} mb={1}
                    >
                        <Grid item xs={1}>
                            <IconButton onClick={() => {backward()}}>
                                <KeyboardArrowLeft fontSize={'large'}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography variant={'h3'} color={'primary.main'} textAlign={'center'}>
                                {e.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={() => {forward()}}>
                                <KeyboardArrowRight fontSize={'large'}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item mb={3} >
                        <Typography variant={'h5'} textAlign={'center'}>
                            {e.helpText}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Dialog>
    </Box>
    )
}

import {useAuth} from "../hooks/useAuth";
import {Typography, Grid, Button} from '@mui/material'
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export default function Logout() {
    const { setAuth} = useAuth()
    const nav = useNavigate()
    useEffect(() => {
        setAuth({userType: undefined, token:'', netid:''})
    }, [])

    return (
        <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} spacing={2}>
            <Grid item>
            <Typography variant={'h3'} color={'primary.main'} sx={{mb:4}}>
                You have been logged out!
            </Typography>
            </Grid>
            <Grid item>
            <Button variant={'contained'} onClick={()=>{nav('/login')}}>
                <Typography variant={'h5'}>Login</Typography>
            </Button>
            </Grid>
        </Grid>
    )
}

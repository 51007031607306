import {createContext, useContext, useState} from 'react'

type AuthType = {userType:UserTypes | undefined, token:string, netid:string}
export interface ContextType {
    auth: AuthType
    setAuth: (newAuth:AuthType) => void;
}



const AuthContext = createContext<ContextType>({
    auth:{userType:undefined, token:'', netid:''},
    setAuth: () => {}
})

export const AuthProvider = ({children}:any) => {
    const [auth, setAuth_] = useState<AuthType>({userType: undefined, token:'', netid:''})

    const setAuth = (newAuth:AuthType) => {
        if (newAuth.userType === undefined) {
            setAuth_(newAuth)
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('reAuthKey')
            sessionStorage.removeItem('netid')
        }
        else {
            sessionStorage.setItem('token', newAuth.token)
            sessionStorage.setItem('netid', newAuth.netid)
            let revtk = newAuth.token.split("").reverse().join("")
            let revtype = newAuth.userType.split("").reverse().join("")
            let revnid = newAuth.netid.split("").reverse().join("")
            let reAuthKey = revtype + ':' + revnid + ':' + revtk
            sessionStorage.setItem('reAuthKey', reAuthKey)
            setAuth_(newAuth)
        }
    }

    return(
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)

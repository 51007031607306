import Form from "../../components/abstract/Form";
import { triggerReset } from "../../ApiClient";
import {useState} from "react"
import { Button, Typography } from "@mui/material";
export default function TriggerReset() {
    const [state, setState] = useState<'form'|'sent'>('form')


    let inputs = [{key:'netid', name:"Netid"}]
    if (state === "form") {
        return (
            <Form
                header="Password Reset Request"
                inputs={inputs}
                specialInputs={[]}
                apiCall={triggerReset}
                handleSuccess={() => {setState('sent')}}
                />
        )
    }
    else {
        return(
        <>
            <Typography variant='h3' mb={3}>
                An email with the instructions for resetting your password has been sent to the email
                associated with your account
            </Typography>
            <Button size='medium' onClick={() => {setState('form')}}>
                <Typography>Resend email</Typography>
            </Button>
        </>
        )
    }
}

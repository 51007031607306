import {useNavigate, useParams} from "react-router-dom";
import { useEffect, useState} from "react";
import {useErrorBoundary} from 'react-error-boundary'
import {getUserProjects, setProjectStatus, updateProjectDeadline} from "../../ApiClient";
import { Button, Grid, Typography, TextField, Snackbar, IconButton, Divider} from "@mui/material";
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@mui/material'
import Loading from "../../components/statics/Loading";
import InfoDisplay from "../../components/abstract/InfoDisplay";
import {isProjectArr, isFailRes} from "../../guards";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ConfirmDecision from "../../components/abstract/ConfirmDecision";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from "@mui/icons-material/Close"
import { EditRounded } from "@mui/icons-material";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import Header from "../../components/styled/Header";
import customParseFormat from "dayjs/plugin/customParseFormat"
export default function ProjectManager() {
    const {netid} = useParams()
    const [projects, setProjects] = useState<Project[]>()
    const [message, setMessage] = useState<undefined | string>()
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getProjects = () => {
        getUserProjects(netid as string)
            .then((res) => {
                if ( Array.isArray(res) && res.length > 0) {
                    if (isProjectArr(res)) {
                        setMessage(undefined)
                        setProjects(res)
                    }
                }
                else if (Array.isArray(res)) {
                    setMessage("No Projects Yet!")
                }
                else if (isFailRes(res)) { setMessage(res.reason) }
            })
            .catch((e) => {showBoundary(e)})
    }
    if (projects && projects.length > 0) {
        let projList = []
        for (let i in projects) {
            let p = projects[i]
            projList.push(<Grid item><ProjectInfo project={p} callback={getProjects} /></Grid>)
        }

        return (
            <Grid container direction={'column'}
                  spacing={2} justifyContent={'center'}
                  alignItems={'center'} mb={3}
            >
                <Grid item>
                    <Header color={'primary.main'} mt={3} variant={'h4'}>
                        My Projects
                    </Header>
                </Grid>
                <Grid item xs={6} width={'50%'} mb={2}>
                    <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                </Grid>
                <Grid xs={8} container direction={'column'} spacing={2} sx={{mt:3}}>
                    {projList}
                </Grid>
            </Grid>
        )
    }
    else if (message) {
        return (
            <Grid container direction='column' justifyContent={'center'} alignItems={'center'}>
                <Grid item mt={10}>
                    <Typography variant='h3'> No Projects Yet!</Typography>
                </Grid>
            </Grid>
        )
    }
    else {
        return (<Loading open={true}/>)
    }
}


function ProjectInfo(props:{project: Project, callback:Function}) {
    const nav = useNavigate()
    const [openDialog, setOpen] = useState(false)
    const [openDeadline, setOpenDeadline] = useState(false)
    const [alertOpen, setAlertOpen] = useState(false)
    const {showBoundary} = useErrorBoundary()
    let p = props.project

    const closeProject = async () => {
        // this line stops the ts compiler from complaining
        let id = p.id as unknown as number
        try{
            await setProjectStatus({projectid:id, status:"closed"})
        }
        catch (e) {
            showBoundary(e)
        }
    }

    let info = [
        {label: "Deadline", info:p.deadline},
        {label: "Owner", info:p.poster},
        {label: "Contact Email", info:p.email},
        {label: "Organization", info:p.organization},
        {label: "Approval", info:p.approval},
        {label: "Funding", info:p.funding},
        {label: "Description", info:p.description}
    ]

    return(
    <InfoDisplay title={p.title} info={info}>
        <Grid container direction='column' spacing={2} mt={2}>
                <Grid
                    container item spacing={1}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item>
                        <Button onClick={
                            () => {nav('../project/'+p.id+'/applications', {relative : "path"})
                        }}
                        variant='contained' size='medium'
                        sx={{minWidth:230}}
                        startIcon={<MenuBookOutlinedIcon />}
                        >
                            <Typography>
                                View Applications
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => {
                            let url = window.location.host + '/apply/'+ p.id
                            navigator.clipboard.writeText(url)
                            setAlertOpen(true)
                        }}
                        startIcon={<IosShareIcon />}
                        sx={{minWidth:230}}
                        variant='contained' size='medium'
                        >
                            <Typography>
                                Share Link
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container item spacing={1}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item>
                        <Button
                            onClick={() => {setOpenDeadline(true)}} startIcon={<EditRounded />}
                            sx={{minWidth:230}}
                            variant='contained' size='medium'
                        >
                            <Typography>
                               Edit Deadline
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => {setOpen(true)}} sx={{minWidth:230}}
                            variant='contained' size='medium' color='error'
                            startIcon={<EventBusyOutlinedIcon />}
                        >
                            <Typography>
                                Close Project
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            <ConfirmDecision
                successCallback={closeProject}
                cancelCallback={()=>{setOpen(false)}}
                title={"Close Project?"}
                text={"This action cannot be reversed without contacting our admins."}
                open={openDialog} />
            <Snackbar
                open={alertOpen}
                onClose={() => {setAlertOpen(false)}}
                message="Link copied to clipboard!"
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={<IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setAlertOpen(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                  }
            />
            <DeadlineDialog
                p={p}
                open={openDeadline}
                setOpen={setOpenDeadline}
                callback={props.callback}
                />
        </Grid>
    </InfoDisplay>
    )
}



function DeadlineDialog(props:{p:Project, open:boolean, setOpen:Function, callback:Function}) {
    dayjs.extend(customParseFormat)
    let d = dayjs(props.p.deadline, "MM-DD-YYYY")
    const [deadline, setDeadline] = useState(d)
    const {showBoundary} = useErrorBoundary()
    const handleCancel = () => {
        props.setOpen(false)
        setDeadline(d)
    }
    const handleSubmit = async () => {
        props.setOpen(false)
        let data = {
            //@ts-ignore
            projectid:parseInt(props.p.id),
            date: deadline.date(),
            month: deadline.month() + 1,
            year: deadline.year(),
        }
        try {
            await updateProjectDeadline(data)
            props.callback()
        }
        catch (e) {showBoundary(e)}
    }
    return (
        <Dialog open={props.open} onClose={()=>{props.setOpen(false)}}>
            <DialogTitle variant={'h4'} color={'primary.dark'}>Update Deadline</DialogTitle>
            <DialogContent>
                <DialogContentText variant={'h6'}>
                    Click submit after selecting a new date to update the deadline of the project.
                    Click cancel to revert your changes to the original deadline.
                </DialogContentText>
                <br></br>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                    label={'Deadline'}
                    inputFormat="MM/DD/YYYY"
                    value={deadline}
                    onChange={(newVal)=>{ // @ts-ignore
                        setDeadline(newVal)}}
                    renderInput={(params:any) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button color='error' onClick={handleCancel}>Cancel</Button>
                <Button color='primary' onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    )

}

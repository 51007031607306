import axios, {AxiosRequestConfig, isAxiosError} from 'axios'

// instance configuration
function createClient(url:string) {
    let client = axios.create({
        baseURL: url,
        timeout: 6500,
        headers: {
            'content-type': 'application/json',
        }
    })
    client.interceptors.request.use((config) => {
        const netid = sessionStorage.getItem('netid')
        const token = sessionStorage.getItem('token')
        // @ts-ignore
        config.headers['auth-token'] = token
        // @ts-ignore
        config.headers['auth-netid'] = netid
        return config
    })
    client.interceptors.response.use(
        (response) => {
            return response.data
        },
        (e) => {
            throw e
        }
    )
    return client
}
const urlMap: {[key: string]: string} = {
    'catcoder.w3.uvm.edu': 'https://catcoder.w3.uvm.edu/api/',
    'catcoders.org': 'https://catcoders.org/api/',
    'localhost': 'http://localhost:5001/api/'
}
let urlUsed = urlMap[window.location.hostname] ? urlMap[window.location.hostname] : urlMap["localhost"]
console.log(urlUsed)
const client = createClient(urlUsed)
async function get<T>(url:string, config?:AxiosRequestConfig): Promise<T | FailRes | Response> {
    return await client.get(url, config)
}
async function post<T>(url:string, data:any, config?:AxiosRequestConfig): Promise<T | FailRes | Response> {
    return await client.post(url, data)
}
async function put<T>(url:string, data?:any, config?:AxiosRequestConfig):
Promise<T | SuccessRes | FailRes> {
    return await client.put(url, data)
}
async function deleteReq<T>(url:string): Promise<T | SuccessRes | FailRes> {
    return await client.delete(url)
}

// AUTH API
interface LoginRes extends SuccessRes {netid:string, token:string, type: "org" | "dev" }
export const login = async(data:{netid:string, password:string}) => {
    return await post<LoginRes>('login', data)
}

interface SignUpReq {
    netid: string
    password:string
    name: string
    email: string
    type: "org" | "dev"
}
export const signup = async(userInfo:SignUpReq) => {
    return await post<SuccessRes>('signup', userInfo)
}


// PROJECTS API
export const getApplicableProjects = async() => {
    return await get<Project[]>('projects?filter=applicable')
}
export const getPubliclyVisibleProjects = async() => {
    return await get<Project[]>('projects?filter=publicly_visible')
}
export const createProject = async(projectInfo: any) => {
    return await post<SuccessRes>('projects', projectInfo)
}
export const getUserProjects = async(netid:string) => {
    return await get<Project[]>('projects/user/'+netid)
}
export const setProjectStatus = async(data:{projectid:number, status: "open"|"closed"}) => {
    return await put<SuccessRes>('projects/'+data.projectid, {update:['status'], status:data.status})
}
export const getAllProjects = async() => {
    return await get<Project[]>('projects')
}
export const setProjectApproval = async(data: {projectid:number, approval: "approved"|"rejected"}) =>{
    return await post<SuccessRes>('projects/update_approval', data)
}
export const updateProjectDeadline = async(data:{projectid:number, date: number, month: number, year:number}) => {
    (data as any)['update'] = ['deadline'];
    return await put<SuccessRes>('projects/'+data.projectid, data)
}
export const getProject = async(projectId:number) => {
    return await get<Project>('projects/'+projectId)
}
type updateInfo = {update:string[], organization?:string, title?:string, description?: string, funding?:string}
export const updateProject = async(info:updateInfo, projectid:number|string) =>{
    return await put('projects/'+projectid, info)
}

// APPLICATION API
export const getApplication = async(appid:number) => {
    return await get<Application>('applications/'+appid)
}
export const getUserApps = async(netid:string) => {
    return await get<Application[]>('applications/user/'+netid)
}
export const getProjectApps = async(projectId:number) => {
    return await get<Application[]>('applications/project/'+projectId)
}
export const updateAppStatus = async(info:{appid: number, status:Application["status"]}) => {
    return await put<SuccessRes>('applications/'+info.appid, {status:info.status})
}
export const createApplication = async(info: CreateApp) => {
    return await post('applications', info)
}
export const saveResume = async(appid : number, resume:FormData) => {
    return await client.post(
        'applications/'+appid+'/resume', resume,
        {headers:{'content-type': 'multipart/form-data'}})
}
export const getResume = async(appid: number) => {
    return await client.get<Blob>('applications/'+appid+'/resume', {responseType:"blob"})
}
export const hasUserApplied = async(projectid:number, netid:string) => {
    return await get<Array<Application>>('applications/user/'+netid+'?project='+projectid)
}
// Accounts API
export const getProfile = async(netid:string) => {
    return await get<DevProfile | OrgProfile>('accounts/'+netid)
}
export const triggerReset = async(data:{netid:string}) => {
    return await post('accounts/trigger_reset', data)
}
export const forgotPassword = async(info:{netid:string, token:string, password:string}) => {
    return await post('accounts/forgot_password', info)
}
export const resetPassword = async(current:string, newPw:string) => {
    return await post('accounts/reset_password', {current:current, newPw: newPw})
}
export const updateEmail = async(email:string, netid:string) => {
    return await put('accounts/'+netid, {update:['email'], email:email})
}
export const updateName = async(name:string, netid:string) => {
    return await put('accounts/'+netid, {update:['name'], name:name})
}
export const updateCheckSetting = async(netid:string, newVal:boolean, property:string) => {
    return await put('accounts/'+netid, {update:[property], val:newVal})
}
export const getNotifications = async(netid:string) => {
    return await get<Notification[]>('accounts/'+netid+'/notifications')
}
export const deleteNotif = async(notifid:string) => {
    return await deleteReq('notifications/'+notifid)
}
export const deleteUserNotifs = async(netid:string) => {
    return await deleteReq('accounts/'+netid+'/notifications')
}
// ADMIN API
export const contactUs = async(message:{email:string, name:string, subject:string, content:string}) => {
    return await post('admin/contact_us',message)
}

export const getSiteSettings = async() => {
    return await get('admin/settings')
}

export const setContactEmail = async(email:string) => {
    return await post('admin/settings', {update: ['contactEmail'], contactEmail: email})
}
export const updateNotifyMe = async(n:boolean) => {
    return await post('admin/settings', {update: ['notifyMe'], notifyMe: n})
}

export const makeAdminAccount = async(data:{netid:string, email:string, password:string}) => {
    return await post('admin/make_admin', data)
}
export const getAccounts = async() => {
    return await get('accounts')
}
export const setUserStatus = async(netid:string, status:"ban"|"unban") => {
    return await put('accounts/'+netid+'/'+status)
}
export const getReports = async() => {
    return await get<Report[]>('admin/reports')
}
interface reportUpdate {funding?:string, budget?:number, hourlyRate?:number}
export const updateReport = async(reportid:number, toUpdate: reportUpdate) => {
    return await put('admin/reports/'+reportid, toUpdate)
}
export const getStats = async() => {
    return await get('admin/stats')
}
export const deleteAccount = async(netid:string) => {
    return await deleteReq('accounts/'+netid)
}
export const remindDevs = async() => {
    return await get('admin/remind_devs')
}
export const adminResetPassword = async(netid:string, pw:string) => {
    return await post('admin/reset_user_pw', {netid:netid, pw:pw})
}

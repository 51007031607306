import {useState, ChangeEvent, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Form from '../../components/abstract/Form'
import {Button, Typography, Stack, TextField} from '@mui/material'
import Publish from "@mui/icons-material/Publish"
import {createApplication, hasUserApplied, saveResume} from "../../ApiClient";
import {isFailRes, isSuccessRes} from "../../guards";
import {useErrorBoundary} from "react-error-boundary";

export default function Apply() {
    const {netid, projectId} = useParams()
    const [cl, setCl] = useState('')
    const [resume, setResume] = useState<File>()
    const {showBoundary} = useErrorBoundary()
    const nav = useNavigate()
    useEffect(() => {
        hasUserApplied(parseInt(projectId as unknown as string), netid as unknown as string)
            .then((res) => {
                if(Array.isArray(res) && res.length === 0) {
                    nav('/dev/'+netid+'/manage')
                }
            })
            .catch((e) => {showBoundary(e)})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    let specialInputs = [
        {key:'resume', state: resume, component: <ResumeUpload value={resume} setter={setResume}/>},
        {key:'coverLetter', state:cl, component: <CoverLetterInput value={cl} setter={setCl}/>}
    ]
    const handleSubmit = async (data: { resume:File, coverLetter:string }) => {
        console.log(resume)
        const info = {
            coverLetter: cl,
            netid: netid as unknown as string,
            projectid:projectId as unknown as number
        }
        console.log(info)
        try {
            let res = await createApplication(info)
            if (isSuccessRes(res) && resume) {
                await timeout(1000)
                let data = new FormData();
                data.append(netid + ' resume', resume, resume.name)
                //@ts-ignore
                let saveResReq = await saveResume(res.id, data)
                if (isSuccessRes(saveResReq)) {
                    nav('/dev/' + netid + '/manage')
                }
            }
        } catch (e) {
            showBoundary(e)
        }
    }
    let confirmationText = "While you are allowed to resubmit your application, the employer " +
        "may discard it as a duplicate, please ensure that everything is filled our properly. You cannot" +
        " edit an application after submitting."

    return(
        <Form header={"Application"}
              inputs={[]} handleSuccess={()=>{}}
              specialInputs={specialInputs} callBack={handleSubmit}
              confDialog={{title:"Submit Application?",text:confirmationText }}
        />
    )
}

const allowedFiles = ["pdf"]
function ResumeUpload(props: {value:any, setter:any}) {
    const [fileName, setFN] = useState<string>()
    const handleUpload = (e:ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            let extension = e.target.files[0].name.split('.').pop()
            if (extension && allowedFiles.includes(extension)) {
                props.setter(e.target.files[0])
                setFN(e.target.files[0].name)
            }
            else {
                setFN("ERROR: INVALID FILETYPE")
            }
        }
        else {
            setFN("ERROR: NO FILE WAS GIVEN")
        }

    }

    return(
        <>
            <Stack direction={'column'} spacing={2} sx={{mb:2}}>
                <Typography variant={'h5'} mb={3}> Resume/CV </Typography>
                <Button variant="contained" component="label" size='medium' sx={{mr:2}}>
                    <Publish /> &nbsp; Upload Resume
                    <input hidden
                           accept="application/pdf"
                           type="file"
                           onChange={(e)=>handleUpload(e)}
                    />
                </Button>
                <Typography variant={'subtitle1'} >{fileName}</Typography>
            </Stack>
        </>
    )
}

function CoverLetterInput(props: {value:any, setter:any}) {
    return (
        <TextField
            fullWidth
            multiline
            minRows={8}
            maxRows={8}
            variant={'outlined'}
            value={props.value}
            label={"Cover Letter"}
            onChange={(e) => {
                props.setter(e.target.value)
            }}
        />
    )
}

function timeout(delay:number) {
    return new Promise(res => setTimeout(res, delay))
}

import { CheckRounded, HighlightOffRounded, EditRounded } from "@mui/icons-material";
import {IconButton, TextField, Grid, Typography} from '@mui/material'
import {useState} from 'react'
interface infoProps {
    callback: Function,
    label: string,
    info: string,
}

export default function EditableInfo(props:infoProps) {
    const [edit, setEdit] = useState(false)
    const [compVal, setCV] = useState<string >(props.info)

    //TODO: debug the confirmation logic (no visual indicator of success)
    let editTf;
    editTf = <TextField label={props.label} fullWidth value={compVal} onChange={(e)=>{setCV(e.target.value)}}/>

    const handleSubmit = () => {
        // trigger api call to update the value
        props.callback(compVal)
        setEdit(false)
    }
    const handleCancel = () => {
        // reset to the orginal value
        // @ts-ignore
        setCV(props.info)
        setEdit(false)
    }
    let inEdit;
    if (edit) {
        inEdit =
        <>
            <Grid item>
                {editTf}
            </Grid>
            <Grid item>
                <IconButton onClick={() => {handleSubmit()}}>
                    <CheckRounded />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={()=>{handleCancel()}}>
                    <HighlightOffRounded />
                </IconButton>
            </Grid>
        </>
    }
    else {
        inEdit =
        <>
            <Grid item>
                <Typography variant={'h5'}><Sb>{props.label}: </Sb> {compVal}</Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={()=>{setEdit(true)}}>
                    <EditRounded />
                </IconButton>
            </Grid>
        </>
    }
    return (
        <Grid container direction={'row'} spacing={2} mb={1} alignItems={'flex-start'} justifyContent={'center'}>
            {inEdit}
        </Grid>
    )
}

function Sb(props:any) {
    return(
        <Typography variant={"inherit"} display={"inline"} fontWeight={'bold'} mr={1}>
            {props.children}
        </Typography>
    )
}
import { useEffect, useState } from "react";
import { getStats } from "../../ApiClient";
import Header from "../../components/styled/Header";
import { Divider, Grid, Typography } from "@mui/material";
import Bolded from "../../components/styled/Bolded";
import Loading from "../../components/statics/Loading";

interface statObj {
    devs: number, orgs: number, openProjs: number,
    closedProjs: number, pendingProjs: number, totalApps: number
}

export default function Statistics() {
    const [stats, setStats] = useState<statObj>()
    useEffect(() => {
        getStats().then((res) => {setStats(res as unknown as statObj)})
    }, [])

    if (stats) {
        return (
            <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2} mt={1}>
                <Grid item >
                    <Header variant='h4' color='primary.main' textAlign={'center'}>Usage Stats</Header>
                </Grid>
                <Grid item xs={6} width={'50%'} mb={1}>
                        <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                </Grid>
                <Grid container xs={8} direction={'column'} alignItems={'flex-start'} spacing={2} mt={1}>
                    <Grid item mt={1.5}>
                        <Typography variant='h6'>
                            <Bolded>Total Users:</Bolded> {stats.devs + stats.orgs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Developers:</Bolded> {stats.devs }
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Organizers:</Bolded> {stats.orgs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Total Projects:</Bolded> {stats.closedProjs+stats.openProjs+stats.pendingProjs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Open Projects:</Bolded>{stats.openProjs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Pending Projects:</Bolded>{stats.pendingProjs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Closed Projects:</Bolded>{stats.closedProjs}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='h6'>
                            <Bolded>Total Applications:</Bolded>{stats.totalApps}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    else {
        return (<Loading open={(stats)?true:false} />)
    }

}

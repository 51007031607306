import Form from '../../components/abstract/Form'
import {signup} from "../../ApiClient";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {Grid, Typography, Switch, useTheme, styled, } from "@mui/material";
import {PasswordField} from "../../components/abstract/FormSpecials";
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';

export default function Signup(props:{handleSignup:Function}) {
    const [type, setType] = useState<'dev'|'org'>('dev')
    const [password, setPassword] = useState<string>('')
    const inputs = [
        {key: "netid", name: "Netid"},
        {key: "name", name:"Name"},
        {key:"email", name:"Email"}
    ]

    const success = (res:any) => {
        console.log('success')
        props.handleSignup()
    }

    let specialInputs = [
        {
            state: password,
            component: <PasswordField value={password} update={setPassword} />,
            key: 'password'
        },
        {
            state : type,
            component: <TypeSelect value={type} setVal={setType} />,
            key: 'type'
        }
    ]
    let dialogText = "While some profile information can be changed, your username cannot be."
    return(
        <Form
            header={undefined}
            inputs={inputs}
            apiCall={signup}
            handleSuccess={success}
            specialInputs={specialInputs}
            confDialog={{title: "Create Account?", text:dialogText}}
            noBorder={true}
            buttonContained
        />
    )
}


const StyledSwitch = styled(Switch)(({theme}) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        color: theme.palette.primary.dark,
        '&.Mui-checked': {
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 20"><path fill="${encodeURIComponent(
        '#fff',
             )}" d="M12 7V3H2v18h20V7H12zm-2 12H4v-2h6v2zm0-4H4v-2h6v2zm0-4H4V9h6v2zm0-4H4V5h6v2zm10 12h-8V9h8v10zm-2-8h-4v2h4v-2zm0 4h-4v2h4v-2z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.secondary.main,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage:`url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 25 20"><path fill="${encodeURIComponent(
        '#fff',
             )}" d="M20 4H4c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.89-2-2-2zm0 14H4V8h16v10zm-2-1h-6v-2h6v2zM7.5 17l-1.41-1.41L8.67 13l-2.59-2.59L7.5 9l4 4-4 4z"/></svg>')`,

        }
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 20 / 2,
    },
    '& .MuiSwitch-colorPrimary': {
        "&.Mui-checked": {
            // thumb - checked
            color: theme.palette.secondary.dark
        }
    },

}));

function TypeSelect(props:{value:any, setVal:any}) {
    // consistency wise false maps to dev and true maps to org
    const [checked, setChecked] = useState(false)
    const handleChange = () => {
        // invert the originial prop
        if (checked) {
            // prior to the change its checked => change from org to dev
            props.setVal('dev')
        }
        else {
            // prior to change its not checked => change from dev to org
            props.setVal('org')
        }
        setChecked(!checked)
    }
    let state;
    if (checked) {
        state = "Organizer"
    } else {
        state = "Developer"
    }
        return (
            <Grid
                container direction={'row'}
                spacing={1} alignItems={'center'}
                justifyContent={'flex-start'}

            >
                <Grid item mr={2} ml={4}>
                    <Typography variant='h5' fontWeight={500}>Account Type:</Typography>
                </Grid>
                <Grid item sx={{minWidth:120, minHeight:43}}>
                    <StyledSwitch
                        checked={checked}
                        onChange={() => {handleChange()}}

                    />
                </Grid>
                <Grid item >
                    <Typography variant='h6' >
                        {state}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

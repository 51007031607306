import {useEffect, useState} from 'react'
import { Typography, Grid, Divider} from '@mui/material'
import {getUserApps} from '../../ApiClient'
import {useErrorBoundary} from 'react-error-boundary'
import {useParams} from "react-router-dom";
import Loading from '../../components/statics/Loading';
import {isApplicationArr, isFailRes} from "../../guards";
import InfoDisplay from "../../components/abstract/InfoDisplay";
import Header from '../../components/styled/Header';

export default function ViewApplications() {
    const [apps, setApps] = useState<Application[]>()
    const [message, setMessage] = useState<string | undefined>()
    const {netid} = useParams()
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        //TODO: Debug this (build empty array condition)
        getUserApps(netid as string)
            .then((res)=>{
                if (isFailRes(res)) {
                    setMessage(res.reason)
                }
                else if (Array.isArray(res) && res.length === 0) {
                    setMessage("No Applications Yet")
                }
                else if (isApplicationArr(res)) {
                    setApps(res)
                }
            })
            .catch((e) => {showBoundary(e)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    let display = []
    if (apps) {
        for (let p of apps) {
            display.push(<Grid item><AppInfo a={p} /></Grid>)
        }
        return (
            <Grid container direction={'column'} alignItems={'center'} spacing={2} justifyContent={'center'}>
                <Grid item>
                    <Header color={'primary.main'} mt={3} variant={'h4'}>
                        My Applications
                    </Header>
                </Grid>
                <Grid item xs={6} width={'50%'} mb={2}>
                    <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                </Grid>
                <Grid xs={6} container direction={'column'} spacing={2} sx={{mt:3}}>
                    {display}
                </Grid>
            </Grid>
        )
    }
    else if (message) {
        return <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            mt={2}
        >
            <Grid item>
                <Typography variant={'h4'}>{message}</Typography>
            </Grid>
        </Grid>
    }
    else {
        return <Loading open={true}/>
    }
}

function AppInfo(props: { a:Application }) {
    const a = props.a
    let info = [
        {label:"Status",info:a.status},
        {label:"Date Submitted", info:a.submitted},
        {label: "Project Owner", info:a.projectManager},
    ]
    return(
        <InfoDisplay title={a.project} info={info} />
    )
}




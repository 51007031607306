import Form from "../components/abstract/Form";
import { contactUs } from "../ApiClient";
import { useState } from "react";
import { SuccessAlert, MultiLineTextField } from "../components/abstract/FormSpecials";
import Box from '@mui/material/Box'
export default function ContactUs() {
    const [content, setContent] = useState<string>()
    const [openAlert, setOpen] = useState<boolean>(false)
    const handleSuccess = async() => {
        setContent(undefined)
        setOpen(true)
    }

    let inputs = [
        {key: 'name', name: "Name"},
        {key: "email", name: "Email"},
        {key: "subject", name: "Subject"},

    ]
    return (
        <Box sx={{mb:2}}>
        <SuccessAlert open={openAlert} setOpen={setOpen} />
        <Form header={"Contact Us"}
            inputs={inputs}
            handleSuccess={handleSuccess}
            specialInputs={[{
                state:content,
                component: <MultiLineTextField value={content} update={setContent} />,
                key: "content"
            }]}
            apiCall={contactUs}
        />
        </Box>
    )
}


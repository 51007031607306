import {getAllProjects, setProjectApproval, setProjectStatus} from "../../ApiClient";
import {useEffect, useState, SyntheticEvent} from "react";
import {isProjectArr, isSuccessRes} from "../../guards";
import InfoDisplay from "../../components/abstract/InfoDisplay";
import Typography from "@mui/material/Typography";
import {Button, Box, Grid, Divider} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from "@mui/icons-material/Close"
import { EditRounded } from "@mui/icons-material";
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import IconButton from "@mui/material/IconButton";
import {Snackbar} from "@mui/material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Loading from "../../components/statics/Loading";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Header from "../../components/styled/Header";
import {useErrorBoundary} from 'react-error-boundary'
type filters = "pending" | "hiring"| "open" | "closed" | "all"

export default function ManageProjects() {
    const [projects, setProjects] = useState<Project[]>()
    const {showBoundary} = useErrorBoundary()
    const [select, setSelect] = useState<filters>("pending")
    useEffect(() => {
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getProjects = () => {
        getAllProjects()
            .then((res)=> {
                if (isProjectArr(res)) {
                    setProjects(res)
                }
            })
            .catch((e) => {showBoundary(e)})
    }

    const handleSelect = (event:SyntheticEvent, val:filters) => {
        setSelect(val)
    }


    if (projects){
        // tob->filter mapping

        let filtered:Project[] = []
        let display;
        switch (select) {
            case "all":
                filtered = projects
                break;
            case "closed":
                filtered = []
                for (let p of projects) {
                    if (p.status === "closed" && p.approval === "approved") {
                        filtered.push(p)
                    }
                }
                break;
            case "open":
                filtered = []
                for (let p of projects) {
                    if (p.status === "open" && p.approval === "approved") {
                        filtered.push(p)
                    }
                }
                break;
            case "pending":
                filtered = []
                for (let p of projects) {
                    if (p.approval === "pending") {
                        filtered.push(p)
                    }
                }
                break;
            case "hiring":
                filtered = []
                for (let p of projects) {
                    if (p.approval === "approved" && p.status === "hiring"){
                        filtered.push(p)
                    }
                }
        }
        display = <Grid item><MakePD projects={filtered} reload={getProjects} /></Grid>

        return (
            <Box sx={{flexGrow:1}}>
                <Grid container direction={'column'}
                  spacing={2} justifyContent={'center'}
                  alignItems={'center'} mb={3} ml={1}
                >
                    <Grid container
                          direction={'column'}
                          spacing={0}
                          alignItems={'center'}
                          justifyContent={'center'}
                          mt={2} mb={1}
                    >
                        <Grid item>
                            <Header variant={'h4'} color={'primary.main'}>
                                Projects
                            </Header>
                        </Grid>
                        <Grid item xs={6} width={'50%'}>
                            <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tabs
                            value={select}
                            onChange={handleSelect}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="pending"
                                label={<Typography variant={'h6'}>Pending &nbsp;Approval</Typography>} />
                            <Tab value="hiring"
                                label={<Typography variant={'h6'}>Hiring</Typography>} />
                            <Tab value="open"
                                label={<Typography variant={'h6'}>Open</Typography>} />
                            <Tab value="closed"
                                label={<Typography variant={'h6'}>Closed</Typography>} />
                            <Tab value="all"
                                label={<Typography variant={'h6'}>All</Typography>} />
                        </Tabs>
                    </Grid>
                    <Grid container direction={'column'} spacing={2} sx={{mt:3}} pr={1.5}>
                        {display}
                    </Grid>
                </Grid>

            </Box>
        )
    }
    else {
        return <Loading open={true}/>
    }
    // mutable display state
        // horizontal menu with filters: Pending Approval, Open, Closed, All
        // clicking a tab filters by that condition and passes those projects to the MakePD component

}


interface pdProps {
    projects: Project[]
    reload: ()=>void
}
function MakePD(props:pdProps) {
    if (props.projects.length !== 0) {
        let toDisplay = []
        for (let p of props.projects) {
            toDisplay.push(<Grid item><ProjectDisplay project={p} reload={props.reload} /></Grid>)
        }
        return <Grid item>{toDisplay}</Grid>
    }
    else {
        return <Typography variant={'h5'}></Typography>
    }
}


function ProjectDisplay(props:{project:Project, reload:()=>void;}) {
    const nav = useNavigate()
    const [alertOpen, setAlertOpen] = useState(false)
    const [message, setMessage] = useState('')
    const {showBoundary} = useErrorBoundary()
    let project = props.project
    let p = project

    function ApproveSelect() {
        return (
            <Grid
                    container item spacing={1}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                <Grid item>
                    <Button sx={{minWidth:230}}
                            variant='contained' size='medium'
                            color={'primary'}
                            onClick={() => handleApprove()}
                            startIcon={<ThumbUpIcon />}
                    >
                        <Typography >
                            Approve
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                <Button sx={{minWidth:230}}
                        variant='contained' size='medium'
                        color={'error'}
                        onClick={() => handleReject()}
                        startIcon={<ThumbDownIcon />    }
                >
                    <Typography>
                        Reject
                    </Typography>
                </Button>
                </Grid>
            </Grid>
        )
    }

    const handleApprove = async () => {
        //@ts-ignore
        let res = await setProjectApproval({projectid: project.id, approval: "approved"})
        if (isSuccessRes(res)) {
            props.reload()
        } else {
            console.log(res)
        }
        // TODO: End of flow
    }
    const handleReject = async () => {
        //@ts-ignore
        let res = await setProjectApproval({projectid: project.id, approval: "rejected"})
        if (isSuccessRes(res)) {
            props.reload()
        } else {
            console.log(res)
        }
    }
    const closeProject = async () => {
        // this line stops the ts compiler from complaining
        let id = p.id as unknown as number
        try{
            await setProjectStatus({projectid:id, status:"closed"})
            setMessage("Project Closed");
            setAlertOpen(true)
        }
        catch (e) {
            showBoundary(e)
        }
    }
    const reOpen= async () => {
        // this line stops the ts compiler from complaining
        let id = p.id as unknown as number
        try{
            await setProjectStatus({projectid:id, status:"open"})
            setMessage("Project ReOpened");
            setAlertOpen(true)
        }
        catch (e) {
            showBoundary(e)
        }
    }


    let button;
    if (props.project.approval === "pending") {
        button = <ApproveSelect/>
    } else {
        if (props.project.status==="open") {
        button = <Grid item>
            <Button onClick={() => {closeProject()}} sx={{minWidth:230}}
                variant='contained' size='medium' color='error'
                startIcon={<EventBusyOutlinedIcon />}
            >
                <Typography>
                    Close Project
                </Typography>
            </Button>
        </Grid>
        }
        else {
            // TODO: FINISH API ENDPOINT
            button = <Grid item>
                <Button onClick={() => {reOpen()}} sx={{minWidth:230}}
                    variant='contained' size='medium' color='error'
                    startIcon={<LockOpenIcon />}
                >
                    <Typography>
                        Re-Open Project
                    </Typography>
                </Button>
            </Grid>
    }
    }
    let info = [
        {label: "Deadline", info:project.deadline},
        {label: "Owner", info:project.poster},
        {label: "Contact Email", info:project.email},
        {label: "Organization", info:project.organization},
        {label: "Approval", info:project.approval},
        {label: "Funding", info:project.funding},
        {label: "Description", info:project.description}
    ]

    return(
        <Box sx={{mb:2}}>
        <InfoDisplay title={project.title} info={info}>
            <Grid container direction='column' spacing={2} mt={2}>
                <Grid
                    container item spacing={1}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item>
                        <Button onClick={() => {
                            let url = window.location.host + '/apply/'+ p.id
                            navigator.clipboard.writeText(url)
                            setMessage("Link copied to clipboard")
                            setAlertOpen(true)

                        }}
                        startIcon={<IosShareIcon />}
                        sx={{minWidth:230}}
                        variant='contained' size='medium'
                        >
                            <Typography>
                                Share Link
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={
                            () => {nav('../project/'+p.id+'/applications', {relative : "path"})
                        }}
                        variant='contained' size='medium'
                        sx={{minWidth:230}}
                        startIcon={<MenuBookOutlinedIcon />}
                        >
                            <Typography>
                                View Applications
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            sx={{minWidth:230}}
                            variant='contained' size='medium'
                            onClick={() => {nav('../projects/'+project.id, {relative:"path"})}}
                            startIcon={<EditRounded />}
                        >
                            <Typography>
                                Edit Info
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container item spacing={0}
                    direction={'row'} alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Grid item>
                    {button}
                    </Grid>

                </Grid>
            </Grid>
            <Snackbar
                open={alertOpen}
                onClose={() => {setAlertOpen(false)}}
                message={message}
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={<IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setAlertOpen(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                  }
            />
        </InfoDisplay>
        </Box>
    )
}

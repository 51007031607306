import {SyntheticEvent, useState} from "react";
import {Button, Card, CardContent, Grid, Tab, Tabs, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

type menuOpts = 'projects' | 'dev' | 'overview'
export default function InfoCard(props:{scroll:()=>void}) {
    const [display, setDisplay] = useState('overview')
    const updateDisplay = (e:SyntheticEvent, newValue:menuOpts) => {
        setDisplay(newValue)
    }
    let content, header, info, link, label;
    if (display === "overview"){
        header = "Connecting student devs to your projects"
        info = "Sign up to start posting your positions for our student developers now"
        link = "/signup"
        label = "Sign Up"

    }
    else if (display === "dev"){
        header = "Build your resume, get experience, and get paid"
        info = "We've filled over a hundred positions, sign up and start applying now."
        link = "/signup"
        label = "Sign Up"
    }
    else {
        header = "Find jobs from all over the country"
        info = "Browse the currently available projects looking for developers."
        link = "/projects"
        label = "View"
    }
    content = <ICContent header={header} info={info} link={link} label={label} scroll={props.scroll} />

    function TabLabel(props:{text:string}) {
        return (
            <Typography fontWeight={'bold'}>
                {props.text}
            </Typography>
        )
    }

    return (
    <Card sx={{maxWidth:'60%',backgroundColor:'transparent'}}>
        <CardContent sx={{backgroundColor:'rgba(256, 256, 256,.8)'}} >
            <Tabs value={display} onChange={updateDisplay} sx={{mb:1}} centered>
                <Tab label={<TabLabel text="Overview" />} value="overview"/>
                <Tab label={<TabLabel text="Developers" />}  value="dev" />
                <Tab label={<TabLabel text="Projects" />}  value="projects"/>
            </Tabs>
            {content}
        </CardContent>
    </Card>
    )
}
interface ICProps {
    header:string,
    info:string,
    link:string,
    label:string,
    scroll: ()=>void
}
function ICContent(props:ICProps) {
    const nav = useNavigate()
    const {header, info, link, label} = props
    return (
        <Grid container direction='column'
              spacing={0} alignItems={'flex-start'}
              justifyContent={'center'} ml={1}
        >
            <Grid item>
                <Typography 
                    variant='h4' color='primary.dark' mt={1} 
                    fontFamily={'oswald'} letterSpacing={'.1rem'} textAlign={'center'}
                    sx={{wordWrap:'break-word', wordBreak:'break-word'}}
                >
                    {header}
                </Typography>
            </Grid>
            <Grid item>
                <Typography 
                    variant='h6' fontWeight={350} mt={2} mr={1}
                    sx={{wordWrap:'break-word', wordBreak:'break-word'}}
                >
                    {info}
                </Typography>
            </Grid>
            <Grid container item direction={'row'}
                  justifyContent={'space-evenly'} alignItems={'flex-end'}
            >
                <Grid item>
                    <Button onClick={()=>{nav(link)}} size={'medium'} variant='contained' sx={{mt:3}}>
                        <Typography>{label}</Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={()=>{props.scroll()}} size={'small'} sx={{mt:1}}>
                        <Typography fontWeight={600} sx={{textDecoration:'underline'}}>
                            Learn More
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const isProject = (p:any): p is Project => {return (p as Project).title !== undefined}
export const isProjectArr = (a:any): a is Project[] => {return isProject((a as Project[])[0])}
export const isApplication = (a:any): a is Application => {return (a as Application).projectManager !== undefined}
export const isApplicationArr =  (a:any): a is Application[] => {return isApplication((a as Application[])[0])}
export const isOrgProfile = (a:any): a is OrgProfile => {return (a as OrgProfile).type === "org"}
export const isDevProfile = (a:any): a is DevProfile => {return (a as DevProfile).type === "dev"}
export const isAdminProfile = (a:any): a is AdminProfile => {return (a as AdminProfile).type === "admin"}
export const isResponse = (a:any): a is Response => {return (a as Response).status !== undefined}
export const isFailRes = (r:any): r is FailRes => {return (!(r as FailRes).success && ((r as FailRes).reason!==undefined))}
export const isSuccessRes = (r:any): r is SuccessRes => { return (r as SuccessRes).success}
export const isNotification = (a:any): a is Notification => {return (a as Notification).text !== undefined}
export const isNotificationArr = (a:any): a is Notification[] => {
    return isNotification((a as Notification[])[0]
)}

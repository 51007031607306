import {Dashboard} from "../Dashboard";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
export default function DevDashboard() {

    let navMap = [
        {
            name: "Profile", link:"profile", icon:<AccountCircleIcon />,
            helpText: "View/change your profile information and settings. If something can be changed" +
                " there will be an icon of a pencil next to it. Toggleable settings have a switch, and " +
                "the label to the right of the switch indicates the current setting."
        },
        {
            name: "My Applications", link:"manage", icon:<HistoryEduIcon />,
            helpText: "View the information of your applications by the title of the project" +
                " you applied to. Click the dropdown to see the status of the application, they are" +
                " ordered from newest to oldest. To apply to new projects, go to the project page linked" +
                "in the navigation bar at the top of the screen, find the project that interests you, click" +
                "on the dropdown to see its information and then click apply to submit an application."
        }
    ]

    return (<Dashboard authType={'dev'} navMap={navMap} />)
}

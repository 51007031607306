import {useState, SyntheticEvent} from "react";
import { Grid, Card, Tab, Tabs, Snackbar, IconButton, Alert, Typography} from "@mui/material";
import CampusBuildingsAbove from "../../assets/CampusBuildingsAbove_comp.jpg"
import CloseIcon from "@mui/icons-material/Close"
import Signup from "./Signup";
import Login from "./Login";

export default function AccountPage(props:{startTab:"login"|"signup"}) {
    const [display, setDisplay] = useState<"login"|"signup">(props.startTab)
    const [alertOpen, setAlertOpen] = useState(false)
    const updateDisplay = (e:SyntheticEvent, newValue:"login"|"signup") => {
        setDisplay(newValue)
    }

    const handleSignup = () => {
        setDisplay("login")
        setAlertOpen(true)
    }
    let content;
    if (display === "login") {
        content = <Login />
    }
    else {
        content= <Signup handleSignup={handleSignup}/>
    }


    return (
        <Grid container direction='column' spacing={0} item sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            width:'100%',
            height:'120vh',
            flexDirection: "row",
            backgroundImage:  `url(${CampusBuildingsAbove})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            // this eliminates the default margin from the navbar
            mt:-3
        }}>
            <Grid item mt={5}>
                <Card sx={{backgroundColor:'rgba(256, 256, 256,.9)'}}>
                    <Tabs value={display} onChange={updateDisplay} centered>
                        <Tab label="Login" value="login" />
                        <Tab label="Sign Up" value="signup" />
                    </Tabs>
                    {content}
                </Card>
            </Grid>
            <Snackbar
                open={alertOpen}
                onClose={() => {setAlertOpen(false)}}
                message="Account Created! Please log in!"
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setAlertOpen(false)}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                  }
            />
        </Grid>

    )

}




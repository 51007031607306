import {Typography, TextField, Box, Grid, Button, MenuItem} from "@mui/material";
import { AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {useState, useEffect} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import Loading from "../../components/statics/Loading";
import {createProject, getProfile} from "../../ApiClient";
import {useErrorBoundary} from 'react-error-boundary'
import {useNavigate} from "react-router-dom";
import {isFailRes, isSuccessRes, isOrgProfile} from "../../guards";
import {useAuth} from "../../hooks/useAuth";
import ConfirmDecision from "../../components/abstract/ConfirmDecision";
import Header from "../../components/styled/Header";
// Legacy design, inconsistent with overall design pattern b/c it's a raw component, not an implementation of Form
export default function ProposeProject() {
    const {auth} = useAuth()
    const {showBoundary} = useErrorBoundary()
    const nav = useNavigate()
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [org, setOrg] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [desc, setDesc] = useState<string>('')
    const [deadline, setDL] = useState<null | Dayjs>(dayjs(new Date()))
    const [funding, setFunding] = useState<"available" | "unavailable" | "expected">("available")
    const [triedSubmit, setTS] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [openDialog, setOpen] = useState(false)
    useEffect(() => {
        getProfile(auth.netid)
            .then((res) => {
                if (isOrgProfile(res)) {
                    setName(res.name)
                    setEmail(res.email)
                }
            })
    }, [])
    const cancelSubmit = () => {
        setOpen(false)
    }

    async function handleSubmit() {
        setOpen(false)
        //validate inputs here
        let inputs = [name, email, org, title, desc, deadline, funding]
        let valid = true
        for (let i of inputs) {
            if ((i === '' || i === null || i=== undefined) && valid) {
                valid = false
            }
        }
        setTS(true)
        if (valid) {
            const data = {
                poster: name,
                email: email,
                organization: org,
                title: title,
                description: desc,
                funding:funding,
                //@ts-ignore
                deadline:deadline.toISOString()
            }
            // if successful
            // make api call here
            setLoading(true)
            try {
                let res = await createProject(data)
                if (isFailRes(res)) {
                    setMessage(res.reason)
                } else if (isSuccessRes(res)) {
                    successfulSubmit()
                }
            } catch (e) {
                showBoundary(e)
            }
        }
        else {
            setMessage("Invalid submission, please ensure all fields have been given the appropriate information")
        }

    }

    const successfulSubmit = () => {
        // clear all inputs
        setTS(false)
        setName("")
        setEmail("")
        setOrg("")
        setTitle("")
        setDesc("")
        nav('/'+auth.userType+'/'+auth.netid+'/manage')
    }

    const fundingOpts = [
        {value: "available", label: "Available"},
        {value: "unavailable", label: "Unavailable"},
        {value: "expected", label: "Expected"}
    ]


    if (loading) {
        return(<Loading open={true} />)
    }
    else {
        return (
            <Box sx={{flexGrow: 1}}>
                <Grid container direction={'column'} spacing={2} justifyContent={'center'} alignItems={'center'}>
                    {/* content root */}
                    <Grid item>
                        <Header color={'primary.main'} mb={2} mt={3} variant={'h3'}>
                            Project Proposal Form
                        </Header>
                    </Grid>
                    <Grid item>
                        <Typography color={'error.main'} fontWeight={'bold'} mb={2} mt={3} variant={'h6'}>
                            {message}
                        </Typography>
                    </Grid>
                    <Grid container direction={'column'} item xs={8} spacing={3}
                          sx={{
                              mt: 2, mb: 2, display: 'flex', flexDirection: 'column', width: '50%', height: 'auto',
                              border: 2, p: 3, borderRadius: 10, borderColor: 'primary.dark'
                          }}>
                        <Grid item>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                label={"Your Name"}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                value={email}
                                label={"Email"}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}/>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                value={org}
                                label={"Organization/Department"}
                                onChange={(e) => {
                                    setOrg(e.target.value)
                                }}/>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                value={title}
                                label={"Project Title"}
                                onChange={(e) => {
                                    setTitle(e.target.value)
                                }}/>
                        </Grid>
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                  label="Deadline"
                                  inputFormat="MM/DD/YYYY"
                                  value={deadline}
                                  onChange={(newVal)=>{setDL(newVal)}}
                                  renderInput={(params:any) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item>
                            <TextField
                                variant={'outlined'}
                                value={funding}
                                label={"Funding"}
                                select
                                size={'medium'}
                                onChange={(e) => {
                                    //@ts-ignore
                                    setFunding(e.target.value)
                                }}
                                sx={{width:225}}
                            >
                                {fundingOpts.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        <Typography variant={'subtitle1'}>{option.label}</Typography>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                multiline
                                minRows={8}
                                maxRows={8}
                                variant={'outlined'}
                                value={desc}
                                label={"Project Description"}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}/>
                        </Grid>
                        <Grid
                            container
                            item
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{mt: 2}}
                        >
                            <Grid item>
                                <Button variant={'outlined'} onClick={() => setOpen(true)} size={'large'}
                                        sx={{width: 300, justifyContent: 'center'}}>
                                    <Typography variant={'h5'} component={'h4'}>Submit</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmDecision
                    successCallback={handleSubmit}
                    cancelCallback={cancelSubmit}
                    title={"Submit Project?"}
                    text={"You may make changes after your project is reviewed by our admins if necessary."}
                    open={openDialog}
                    />
            </Box>
        )
    }
}


import {TextField, Dialog, DialogActions, DialogTitle, Button, Typography} from "@mui/material";

export function PasswordField(props: {value:string, update: (val:any) => void}) {
    return(
        <TextField
            value={props.value}
            onChange={
                (e) => {props.update(e.target.value)}
            }
            type={'password'}
            variant={'outlined'}
            label={'Password'}
            fullWidth
            sx={{mb:2, maxWidth:500}}
        />
    )
}

export function MultiLineTextField(props:{value:string | undefined, update:(e:any)=>void;}) {
    return (
        <TextField
            fullWidth multiline minRows={8} maxRows={8}
            variant={'outlined'} value={props.value} label={"Message"}
            onChange={(e) => {
                props.update(e.target.value)
        }}/>
    )
}

export function SuccessAlert(props: {open:boolean, setOpen:(open:boolean) => void;}) {

    const close = () => {
        props.setOpen(false)
    }

    return (
        <Dialog
            open={props.open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography variant='h5' textAlign='center'>
                    Thank you for contacting us, your message has been sent!
                </Typography>
            </DialogTitle>
            <DialogActions>
                <Button onClick={close} variant='contained' size='large' autoFocus>
                    <Typography>Close</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
import Navbar from "./global/Navbar";
import {CssBaseline, ThemeProvider, Box} from "@mui/material";
import theme from "./theme";
import {createBrowserRouter, RouterProvider, Outlet, Navigate} from "react-router-dom";
import {ProjectsView} from "./pages/ProjectsView";
import Home from "./pages/Home";
import OrgDashboard from './users/Organizer/OrgDashboard'
import DevDashboard from "./users/Developer/DevDashboard";
import Footer from "./global/Footer"
import RouteErrorPage from "./global/RouteErrorPage"
import ViewApplications from "./users/Developer/ViewApplications";
import {Profile} from './users/Profile'
import ProjectManager from "./users/Organizer/ProjectManager";
import ProposeProject from "./users/Organizer/ProposeProject";
import {AuthProvider} from "./hooks/useAuth";
import ApplicationDisplay from "./users/Organizer/ApplicationDisplay";
import EditProject from "./users/Admin/EditProject"
import Apply from "./users/Developer/Apply";
import AdminDashBoard from "./users/Admin/AdminDashboard";
import ManageProjects from "./users/Admin/ManageProjects";
import Logout from "./pages/Logout";
import ContactUs from './pages/Contact';
import TriggerReset from './pages/password/TriggerReset';
import SiteSettings from './users/Admin/SiteSettings';
import ForgotPassword from './pages/password/ForgotPassword';
import AccountPage from './pages/access/AccountPage';
import MakeAdmin from './users/Admin/MakeAdmin';
import ManageAccounts from './users/Admin/ManageAccounts';
import ProjectLinkLanding from './pages/ProjectLinkLanding';
import Reports from './users/Admin/Reports';
import Statistics from './users/Admin/Statistics';
import {ErrorBoundary} from 'react-error-boundary'
import AccountErrorPage from "./users/AccountErrorPage";
function Root(props: {error?:boolean}) {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }}>
            <Navbar />
            <ErrorBoundary FallbackComponent={AccountErrorPage} onError={(e)=>console.error(e)}>
                {props.error? <RouteErrorPage /> : <Outlet />}
            </ErrorBoundary>
            <Footer />
        </Box>
    )
}
function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root />,
            errorElement: <Root error />,
            children: [
                {index: true, element: <Navigate to={'home'} />},
                {path: "error/:statusCode/:statusText", element: <RouteErrorPage />},
                {path: "home",element: <Home />},
                {path:"login", element: <AccountPage startTab='login'/>},
                {path: "signup", element: <AccountPage startTab='signup'/>},
                {path:"logout", element:<Logout />},
                {path: "projects",element:<ProjectsView />},
                {path: "contactus", element:<ContactUs />},
                {path: "trigger_reset", element:<TriggerReset />},
                {path: "forgot_password/:token", element:<ForgotPassword />},
                {path: "apply/:projectid", element:<ProjectLinkLanding />},
                {
                    path:'org/:netid/',
                    element: <OrgDashboard />,
                    children: [
                        {index: true, element: <Navigate to={'manage'} />},
                        {path: "profile", element: <Profile />},
                        {path: "manage", element: <ProjectManager />},
                        {path: "project/:projectId/applications", element: <ApplicationDisplay />},
                        {path: "propose", element: <ProposeProject />},
                ]},
                {
                    path: 'dev/:netid/',
                    element: <DevDashboard />,
                    children: [
                        {index: true, element: <Navigate to={'manage'}/>},
                        {path:"profile", element: <Profile />},
                        {path:"manage", element: <ViewApplications />},
                        {path:"apply/:projectId", element: <Apply />}
                ]},
                {
                    path: 'admin/:netid/',
                    element: <AdminDashBoard />,
                    children: [
                        {index: true, element: <Navigate to={'projects'}/>},
                        {path:"profile", element: <Profile />},
                        {path:"projects", element: <ManageProjects />},
                        {path: "projects/:projectId", element: <EditProject /> },
                        {path:"settings", element: <SiteSettings />},
                        {path:"make_admin", element:<MakeAdmin />},
                        {path:"accounts", element:<ManageAccounts />},
                        {path:"reports", element:<Reports />},
                        {path:"project/:projectId/applications", element: <ApplicationDisplay/>},
                        {path:"statistics", element:<Statistics/>}
                    ]
                }
            ]
        }
    ])

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <AuthProvider>
            <RouterProvider router={router} />
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App;

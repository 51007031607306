import { useState, useEffect } from "react";
import {Typography, Grid, Switch, Snackbar, IconButton, Button} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
export default function SettingSwitch(props:{callback:Function, ogVal:boolean, label:string}){
    const [checked, setChecked] = useState<boolean>(props.ogVal)
    const [alertOpen, setAlertOpen] = useState(false)
    console.log('original: ' + props.ogVal)
    return (
        <Grid container item direction={'row'} spacing={1}>
            <Grid item>
                <Typography variant='h5'>
                    <Sb>{props.label}:</Sb>
                </Typography>
            </Grid>
            <Grid item>
                <Switch
                    checked={checked} onChange={()=>{
                        setChecked(!checked)
                        setAlertOpen(true)
                        props.callback()
                    }}/>
            </Grid>
            <Snackbar
                open={alertOpen}
                onClose={() => {setAlertOpen(false)}}
                message="Setting Updated!"
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                action={<IconButton
                            size="small"
                            color="inherit"
                            onClick={() => setAlertOpen(false)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                  }
            />
        </Grid>

    )
}


function Sb(props:any) {
    return(
        <Typography variant={"inherit"} display={"inline"} fontWeight={'bold'} mr={1}>
            {props.children}
        </Typography>
    )
}

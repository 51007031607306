import {useState, useEffect} from 'react'
import {useParams} from "react-router-dom";
import {getProject, updateProjectDeadline} from "../../ApiClient";
import {isProject} from "../../guards";
import {Typography, Grid, IconButton, TextField, MenuItem} from '@mui/material'
import {EditRounded, CheckRounded, HighlightOffRounded} from '@mui/icons-material'
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import EditableInfo from '../../components/abstract/EditableInfo';
import { updateProject } from '../../ApiClient';
import {useErrorBoundary} from 'react-error-boundary'
import customParseFormat from 'dayjs/plugin/customParseFormat'

export default function EditProject() {
    const [project, setProject] = useState<Project>()
    const {projectId} = useParams()
    const [message, setMessage] = useState<string>()
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        loadProject()
    }, [])
    const loadProject = () => {
        getProject(projectId as unknown as number)
            .then((res) => {
                if (isProject(res)) {
                    setProject(res)
                }
                else {
                    setMessage("There was an error retrieving this project, please retry")
                }
            })
            .catch((e) => {showBoundary(e)})
    }
    const handleDeadline = async(s:string) => {
        let d = dayjs(s, "MM-DD-YYYY")
        let data = {
            //@ts-ignore
            projectid:parseInt(projectId),
            date: d.date(),
            month: d.month()+1,
            year: d.year()
        }
        updateProjectDeadline(data).then((res)=>{
            setMessage("Project Updated")
            loadProject()
        })
    }
    const updateTitle = async (newTitle:string)=> {
        try{
            await updateProject({update:['title'], title:newTitle}, projectId as unknown as string)
            setMessage("Title updated")
        }
        catch (e) {showBoundary(e)}
    }
    const updateOrganization = async (newOrg:string) => {
        try{
            await updateProject({update:['organization'], organization:newOrg}, projectId as unknown as string)
            setMessage("Organization updated")
        }
        catch (e) {showBoundary(e)}

    }
    const updateDescription = async (newDesc:string) => {
        try{
            await updateProject({update:['description'], description:newDesc}, projectId as unknown as string)
            setMessage("Description updated")
        }
        catch (e) {showBoundary(e)}
    }
    const updateFunding = async (newFunding:string) => {
        try {
            await updateProject({update:['funding'], funding:newFunding}, projectId as unknown as string)
            setMessage("Funding updated")
        }
        catch (e) {showBoundary(e)}
    }

    const fundingOpts = [
        {value: "available", label: "Available"},
        {value: "unavailable", label: "Unavailable"},
        {value: "expected", label: "Expected"}
    ]

    if (project) {
        const details = {mb:1}

        return (
            <Grid
                container
                direction={'column'}
                spacing={2}
                alignItems={'flex-start'}
                justifyContent={'center'}
                ml={5}
            >
                <Grid item><Typography variant={'h4'}>{message} </Typography></Grid>
                <Grid item>
                    <EditableInfo
                        callback={updateTitle}
                        label={"Title"}
                        info={project.title}
                        />
                </Grid>
                <Grid item>
                    <InfoLine
                        callback={handleDeadline}
                        label={'Deadline'}
                        info={project.deadline}
                        time={true}
                        />
                </Grid>
                <Grid item>
                    <Typography variant={'h5'} sx={details}>
                        <Sb>Owner: </Sb> {project.poster}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'h5'} sx={details}>
                        <Sb>Contact Email:</Sb> {project.email}
                    </Typography>
                </Grid>
                <Grid item>
                    <EditableInfo
                        callback={updateOrganization}
                        label={"Organization"}
                        info={project.organization}
                        />
                </Grid>
                <Grid item>
                    <InfoLine
                        callback={updateFunding}
                        label={"Funding"}
                        info={project.funding}
                        selector={fundingOpts}
                        />
                </Grid>
                <Grid item>
                    <Typography variant={'h5'} sx={details}>
                        <Sb>Approval:</Sb> {project.approval}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'h5'} sx={details}>
                        <Sb>Status:</Sb> {project.status}
                    </Typography>
                </Grid>
                <Grid item>
                    <InfoLine
                        callback={updateDescription}
                        label={"Description"}
                        info={project.description}
                        multiline={true}
                        />
                </Grid>
            </Grid>
        )
    }
    else {
        return (
            <Typography variant={'h4'}>LOADING...</Typography>
        )
    }
}

function Sb(props:any) {
    return(
        <Typography variant={"inherit"} display={"inline"} fontWeight={'bold'} mr={1}>
            {props.children}
        </Typography>
    )
}
interface infoProps {
    callback: Function,
    label: string,
    info: string,
    time?: boolean,
    multiline?: boolean,
    selector?: Array<{value:string, label:string}>
}
function InfoLine(props:infoProps) {
    dayjs.extend(customParseFormat)
    const [edit, setEdit] = useState(false)
    const [compVal, setCV] = useState<string >(props.info)
    const [_val, _setVal] = useState(dayjs(props.info, "MM-DD-YYYY"))

    let editTf;

    const handleTimeChange = (d:dayjs.Dayjs) => {
        setCV(d.format("MM-DD-YYYY"))
        _setVal(d)
    }

    if (props.time) {
        editTf =
            <Grid item>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label={props.label}
              inputFormat="MM/DD/YYYY"
              value={_val}
              onChange={(newVal)=>{ // @ts-ignore
                  handleTimeChange(newVal)}}
              renderInput={(params:any) => <TextField {...params} />}
            />
        </LocalizationProvider>
                </Grid>
    }
    else if (props.multiline) {
        editTf = <TextField
                    label={props.label}
                    fullWidth
                    value={compVal}
                    onChange={(e)=>{setCV(e.target.value)}}
                    multiline
                    minRows={8}
                    maxRows={8}
                />
    }
    else if (props.selector) {
        editTf = <TextField
                label={props.label}
                fullWidth
                value={compVal}
                select
                sx={{width:225}}
                onChange={(e)=>{setCV(e.target.value)}}
                >
                {props.selector.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Typography variant={'subtitle1'}>{option.label}</Typography>
                    </MenuItem>
                ))}
                </TextField>
    }
    else {
        editTf = <TextField label={props.label} fullWidth value={compVal} onChange={(e)=>{setCV(e.target.value)}}/>
    }

    const handleSubmit = () => {
        props.callback(compVal)
        setEdit(false)
    }
    const handleCancel = () => {
        // @ts-ignore
        setCV(props.info)
        setEdit(false)
    }
    let inEdit;
    if (edit) {
        inEdit =
        <>
            <Grid item>
                {editTf}
            </Grid>
            <Grid item>
                <IconButton onClick={() => {handleSubmit()}}>
                    <CheckRounded />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={()=>{handleCancel()}}>
                    <HighlightOffRounded />
                </IconButton>
            </Grid>
        </>
    }
    else {
        inEdit =
        <>
            <Grid item>
                <Typography variant={'h5'}><Sb>{props.label}: </Sb> {compVal}</Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={()=>{setEdit(true)}}>
                    <EditRounded />
                </IconButton>
            </Grid>
        </>
    }
    return (
        <Grid container direction={'row'} spacing={2} mb={1} alignItems={'flex-start'} justifyContent={'center'}>
            {inEdit}
        </Grid>
    )
}

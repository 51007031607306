import {useNavigate} from "react-router-dom";
import {ReactElement, useState} from 'react'
import {Box, List, ListItemText, ListItemButton, ListItemIcon,
    Typography, IconButton, Menu, MenuItem,  Divider} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {MouseEvent} from "react";

type NavMap = Array<{name:string, link:string, icon:ReactElement | undefined}>
export default function SideNavBar(props:{navMap:NavMap }) {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const nav = useNavigate()
    const navMap = props.navMap
    // triggers taken from mui tutorial
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const isSelected = (link:string) => {
        let pathSplit = window.location.pathname.split('/')
        return (pathSplit.includes(link.replace('/', '')))
    }
    return (
        <>
        <Box sx={{flexGrow:1, display:{xs:'none', md:'flex'}}}>
        <List
            sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper', mt:1.5, ml:2 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <Typography
                    variant={'h4'}
                    letterSpacing={'.1rem'}
                    mb={1}
                    textAlign={'center'}
                    color="primary.main"
                >
                    Menu
                    <Divider variant='middle' sx={{mt:1, borderBottom:1, bgcolor:'primary.dark'}}/>
                </Typography>
            }
        >

            {navMap.map((item) => (
                <ListItemButton onClick={()=>{nav(item.link)}} key={item.name}>
                <ListItemIcon>
                    {item.icon}
                </ListItemIcon>
                    <ListItemText primary={
                        <Typography
                            variant={'h5'}
                            color={
                            (isSelected(item.link))?'secondary.dark':'primary.dark'
                        }
                        sx={{"&:hover": {color:'secondary.dark'}}}
                        >
                            {item.name}
                        </Typography>
                    }/>
                </ListItemButton>
            ))}
        </List>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                {props.navMap.map((page) => (
                    <MenuItem key={page.name} onClick={()=>{
                        handleCloseNavMenu()
                        nav(page.link)
                    }}>
                    <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
        </>
    )

}

import {Grid, Button, Typography, Divider} from '@mui/material'
import {getProfile, updateCheckSetting} from "../ApiClient";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Loading from "../components/statics/Loading";
import {isDevProfile, isAdminProfile, isOrgProfile} from "../guards";
import EditableInfo from '../components/abstract/EditableInfo';
import { updateName, updateEmail } from '../ApiClient';
import SettingSwitch from '../components/abstract/SettingsSwitch';
import Bolded from '../components/styled/Bolded';
import Header from '../components/styled/Header';
import { useErrorBoundary } from 'react-error-boundary';
export function Profile() {
    const {netid} = useParams()
    const [profile, setProfile] = useState<DevProfile|OrgProfile|AdminProfile>()
    const {showBoundary} = useErrorBoundary()
    useEffect(() => {
        getProfile(netid as string)
            .then((res)=> {
                if (isDevProfile(res) || isOrgProfile(res) || isAdminProfile(res)) {
                    setProfile(res)
                    console.log(res)
                }
            })
            .catch((e) => {showBoundary(e)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleUpdateEmail = async(newEmail:string) => {
        try {
            await updateEmail(newEmail, netid as unknown as string)
        }
        catch (e) {
            showBoundary(e)
        }
    }

    const handleUpdateName = async(newName:string) => {
        try {
            await updateName(newName, netid as unknown as string)
        }
        catch (e) {
            showBoundary(e)
        }
    }
    const handleUpdateCheck = async (val:boolean, property:string) => {
        try {
            //@ts-ignore
            await updateCheckSetting(netid, val, property)
        }
        catch (e) {
            showBoundary(e)
        }
        console.log('updated')
    }

    if (profile) {
        let accountLabels = {
            "org": "Organizer",
            "dev": "Developer",
            "admin": "Admin"
        }
        let typeSpecific;
        if (isDevProfile(profile)) {
            typeSpecific = <Grid item>
                <SettingSwitch
                    label={"Notify when a new project is posted"}
                    callback={()=>{handleUpdateCheck(!profile.notifyOnProject, 'notify_on_project')}}
                    ogVal={profile.notifyOnProject}
                    />
            </Grid>
        }
        else if (isOrgProfile(profile)) {
            typeSpecific = <Grid item>
                <SettingSwitch
                    label={"Notify when a new application to your project is submitted."}
                    callback={()=>{handleUpdateCheck(!profile.notifyOnApplication, 'notify_on_application')}}
                    ogVal={profile.notifyOnApplication}
                    />
            </Grid>
        }
        else {
            typeSpecific = undefined
        }

         return (
             <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} spacing={2} mt={1}>
                <Grid container xs={8} direction={'column'} alignItems={'flex-start'} spacing={2} >
                 <Grid container item direction={'column'} spacing={0} alignItems={'center'}>
                     <Grid item>
                         <Header variant={'h4'} color={'primary.main'}>Profile </Header>
                     </Grid>
                     <Grid item xs={6} width={'50%'}>
                        <Divider flexItem sx={{borderBottom:2.5, borderRadius:1, color:'primary.dark', mt:1.5, mb:1}}/>
                    </Grid>
                 </Grid>
                 <Grid item mb={2}>
                     <Typography variant={'h4'}>
                         {profile.netid}
                     </Typography>
                 </Grid>
                <Grid item>
                    <Typography variant={'h5'}>
                        <Bolded>Account Type:</Bolded> {accountLabels[profile.type]}
                    </Typography>
                </Grid>
                 <Grid item>
                    <EditableInfo callback={handleUpdateName} label={'Display Name'} info={profile.name} />
                </Grid>
                <Grid item>
                    <EditableInfo callback={handleUpdateEmail} label={'Email'} info={profile.email} />
                </Grid>
                {typeSpecific}
                 <Grid item>
                    <Button variant='contained' size='medium'>
                        <Typography>Reset Password</Typography>
                    </Button>
                 </Grid>
                 </Grid>
             </Grid>
         )
    }
    else {
        return <Loading open={true}/>
    }

}


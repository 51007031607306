import { useParams } from "react-router-dom";
import useRedirectAuthSafe from "../hooks/useRedirectAuthSafe";
import { useAuth } from "../hooks/useAuth";
import {useEffect} from 'react'
export default function ProjectLinkLanding() {
    const {projectid} = useParams()
    const rAS = useRedirectAuthSafe()
    const {auth} = useAuth()
    useEffect(() => {
        rAS("dev",'/dev/'+auth.netid+'/apply/'+projectid?.toString(), {project:projectid} )
    }, [])
    return (
        <div></div>
    )
}